import React, { useState, useContext, useEffect, useCallback } from 'react'
import PrimarySetting from './primarysetting/primarysetting'
import Context from '../../store/context'
import { Tooltip } from 'antd'
const Settings = () => {
  const { state, actions } = useContext(Context)
  const [tempState, setTempState] = useState({})
  const [prevColor, setPreviousColor] = useState('')

  const [paletteNum, setPaletteNum] = useState(null)

  const setOtherColor = color => {
    let temp = state
    temp.color[paletteNum].hex = color.hex

    console.log(prevColor, 'prev color')

    temp.homescreen.map((node, i) => {
      if (node.textColor == prevColor) {
        temp.homescreen[i].textColor = color.hex
        // console.log(temp.homescreen[i].textColor, 'text color')
      }
    })

    actions({ type: 'setState', payload: { ...temp } })
  }

  const setPalette = i => {
    let temp = state
    let _prev = temp.color[i].hex
    // console.log(_prev, i, '_prev')
    setPreviousColor(_prev)
    setPaletteNum(i)
  }

  const createPalette = color => {
    let _palette = []

    if (color != '') {
      let _color = color.replace('#', '')
      let percent = -30
      let _percent = 60

      let aRgbHex = _color.match(/.{1,2}/g)
      let r = parseInt(aRgbHex[0], 16)
      let g = parseInt(aRgbHex[1], 16)
      let b = parseInt(aRgbHex[2], 16)

      let R = parseInt((r * (100 + percent)) / 100)
      let G = parseInt((g * (100 + percent)) / 100)
      let B = parseInt((b * (100 + percent)) / 100)

      R = R < 255 ? R : 255
      G = G < 255 ? G : 255
      B = B < 255 ? B : 255

      var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16)
      var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16)
      var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16)

      _palette.push('#' + RR + GG + BB)

      let _R = parseInt((r * (100 + _percent)) / 100)
      let _G = parseInt((g * (100 + _percent)) / 100)
      let _B = parseInt((b * (100 + _percent)) / 100)

      _R = _R < 255 ? _R : 255
      _G = _G < 255 ? _G : 255
      _B = _B < 255 ? _B : 255

      var _RR = _R.toString(16).length == 1 ? '0' + _R.toString(16) : _R.toString(16)
      var _GG = _G.toString(16).length == 1 ? '0' + _G.toString(16) : _G.toString(16)
      var _BB = _B.toString(16).length == 1 ? '0' + _B.toString(16) : _B.toString(16)
      _palette.push('#' + _RR + _GG + _BB)
    }
    return _palette
  }

  useEffect(() => {
    let _preset = []
    let temp = state
    let other = []

    temp.color.map((node, i) => {
      if (node.type == 'pri') {
        _preset = [...createPalette(node.hex)]
      } else if (node.type == 'sec') {
        _preset = [..._preset, ...createPalette(node.hex)]
      }
    })

    let j = 0
    let _others = temp.color
    temp.color.map((node, i) => {
      switch (node.type) {
        case 'pripa':
          _others[i].hex = _preset[j]
          j = j + 2
          break
        case 'secpa':
          _others[i].hex = _preset[j]
          j = 1
          break
        default:
          break
      }
    })

    // console.log(_others, _preset, 'others')
    temp.color = _others

    // temp.color.map((nodeColor, i) => {
    //   temp.color[i].count = 0
    //   temp.homescreen.forEach(nodeHome => {
    //     if (nodeColor.hex == nodeHome.textColor) {
    //       temp.color[i].count++
    //     }
    //   })
    // })

    // console.log(temp.color, ' Temp after count')

    setTempState(temp)
  }, [state, tempState])

  // useEffect(() => {
  //   // console.log('temstate change', state.color, tempState.color)
  // }, [tempState])

  return (
    <>
      <div className="settings_main">
        <h3>Primary Settings</h3>
        <div className="settings_primary">
          <div className="settings_color_palette">
            {tempState.hasOwnProperty('color') &&
              tempState.color.map((node, i) => {
                return (
                  <div key={i}>
                    <div
                      className="settings_palette_container"
                      onClick={() => {
                        setPalette(i)
                      }}
                    >
                      <PrimarySetting
                        value={setOtherColor}
                        mycolor={node.hex}
                        width={'50px'}
                        height={'50px'}
                      />
                    </div>
                    <p
                      className="settings_counts"
                      style={{
                        position: 'absolute',
                        marginTop: '-35px',
                        marginLeft: '85px',
                        backgroundColor: '#999999',
                        borderRadius: '50%',
                        width: '22px',
                        paddingLeft: '7px',
                        color: 'white',
                      }}
                    >
                      {node.count}
                    </p>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings
