import { getInstance as getPortalManagerInstance } from './portal-manager'
import _ from 'lodash'

export const launchAppInPortal = (el, props) => {
  console.log('portallunch', el, props)
  const portalManagerInstance = getPortalManagerInstance()
  if (!portalManagerInstance) {
    throw new Error('React App not initialised....')
  }
  return portalManagerInstance.addAppPortal(el, props)
}

export const clearAppPortals = () => {
  const portalManagerInstance = getPortalManagerInstance()
  if (!portalManagerInstance) {
    throw new Error('React App not initialised....')
  }
  return portalManagerInstance.clearAllPortals()
}

export const isReactApp = url => {
  console.log('is react app', url)
  const values = ['/mblapp', '/mblapp/']

  return _.some(values, el => _.includes(url, el))
}

export const setData = (key, data) => {
  const portalManagerInstance = getPortalManagerInstance()
  portalManagerInstance.setData(key, data)
}

export const getData = key => {
  const portalManagerInstance = getPortalManagerInstance()
  return portalManagerInstance.getData(key)
}
