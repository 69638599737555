import React, { useEffect, useState, useContext } from 'react'
import LocationMenu from './locationmenu'
import LocationHome from './home'
import '../homescreen/phone.css'
import './locationphone.css'
import LocationGallery from './gallery'
import LocationBarber from './barbers'
import LocationHour from './hours'
import LocationReview from './reviews'
import LocationMission from './mission'
import LocTabs from '../previewtab/locationtabs'
import LocationService from './services'
const LocationContainer = () => {
  const [screen, setscreen] = useState({
    locationscreen: 'home',
    locationIndex: 0,
    barber: 0,
  })

  const setDisplay = (sc, i, j) => {
    console.log(j)
    setscreen({ ...screen, locationscreen: sc, locationIndex: i, barber: j })
  }

  const switchParams = () => {
    // console.log(screen.locationscreen)
    switch (screen.locationscreen) {
      case 'home':
        return <LocationHome data={screen} />
      case 'services':
        return <LocationService data={screen} />
      case 'gallery':
        return <LocationGallery data={screen} />
      case 'barbers':
        return <LocationBarber data={screen} />
      case 'hours':
        return <LocationHour data={screen} />
      case 'mission':
        return <LocationMission data={screen} />
      case 'reviews':
        return <LocationReview data={screen} />
    }
  }

  useEffect(() => {}, [screen])

  return (
    <>
      <div style={{ display: 'flex' }}>
        <LocationMenu data={setDisplay} />
        <div style={{ width: '70%', marginLeft: '5%' }}>{switchParams()}</div>
      </div>
    </>
  )
}

export default LocationContainer
