import axios from 'axios'
const SERVER_URL = process.env.MOBILE_APP_BACKEND_URL

export const callPostApi = async (endpoint, body) => {
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  const result = await axios
    .post(SERVER_URL + endpoint, body)
    .then(res => res)
    .catch(err => err)

  return result
}

export const callNameApi = async (endpoint, body) => {
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  const result = await axios
    .post(SERVER_URL + endpoint, body)
    .then(res => res)
    .catch(err => err)

  return result
}

export const callUpdateTagApi = async (endpoint, body) => {
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  const result = await axios
    .post(SERVER_URL + endpoint, body)
    .then(res => res)
    .catch(err => err)
  return result
}

export const callDeleteTagApi = async (endpoint, body) => {
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  const result = await axios
    .post(SERVER_URL + endpoint, body)
    .then(res => res)
    .catch(err => err)
  return result
}

export const callUpdateApi = async (endpoint, body) => {
  console.log(body, 'callUpdatApi')
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  const result = await axios
    .post(SERVER_URL + endpoint, body)
    .then(data => data)
    .catch(err => err)
  return result
}

export const callGetApi = async (endpoint, body) => {
  const result = await axios
    .get(
      SERVER_URL + endpoint + `/small/${body.id}/${body.path}/${body.display_name}`,
    )
    .then(res => {
      console.log(res)
      return res
    })
    .catch(err => {
      console.log('Error')
      return err
    })
  return result
}

export const callGetAllApi = async (endpoint, body) => {
  const result = await axios
    .get(SERVER_URL + endpoint + `/${body}`)
    .then(res => {
      console.log(res)
      return res
    })
    .catch(err => {
      console.log('Error')
      return err
    })
  return result
}

export const callTagApi = async (endpoint, body) => {
  const result = await axios
    .get(endpoint + '?bids=3,4,5')
    .then(data => {
      console.log(data, 'tags Data after fetch')
      return data
    })
    .catch(err => console.log(err, 'error while gettimg tags'))
  return result
}

export const callDeleteApi = async (endpoint, body) => {
  console.log(SERVER_URL + endpoint + `?${body}`)
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  const result = await axios
    .post(SERVER_URL + endpoint, body)
    .then(data => {
      console.log(data, 'data after delete')
      return data
    })
    .catch(err => {
      console.log(err, 'error while deleteing')
      return err
    })

  return result
}

//////////////////
export const callCappUnpub = async (endpoint, body) => {
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  const result = await axios
    .post(SERVER_URL + endpoint, body)
    .then(data => {
      console.log(data, 'data after delete')
      return data
    })
    .catch(err => {
      console.log(err, 'error while deleteing')
      return err
    })

  return result
}

export const callAllCapp = async (endpoint, body) => {
  console.log(SERVER_URL + endpoint + `/${body}`)
  const result = await axios
    .get(SERVER_URL + endpoint + `/${body}`)
    .then(data => {
      console.log(data, 'versions after fetch')
      return data
    })
    .catch(err => console.log(err, 'error while gettimg versions'))
  return result
}

export const callupdateCapp = async (endpoint, body) => {
  console.log('Heyo')
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  const result = await axios
    .post(SERVER_URL + endpoint, body)
    .then(data => {
      console.log(data, 'data after delete')
      return data
    })
    .catch(err => {
      console.log(err, 'error while deleteing')
      return err
    })

  return result
}
