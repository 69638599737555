import { useState, useEffect } from 'react'

const useGlobalStore = () => {
  const [state, setState] = useState({
    logo: {
      src: '',
    },
    color: [
      {
        hex: '#fffffe', //1
        count: 0,
        type: 'pri',
      },
      {
        hex: '#fffeff', //2
        count: 0,
        type: 'sec',
      },
      {
        hex: '#000000', //3
        count: 0,
        type: 'bl',
      },
      {
        hex: '#ffffff', //4
        count: 0,
        type: 'wh',
      },
      {
        hex: '#fffdff', //5
        count: 0,
        type: 'o',
      },
      {
        hex: '#fdffff', //6
        count: 0,
        type: 'o',
      },
      {
        hex: '#fffffc', //7
        count: 0,
        type: 'o',
      },
      {
        hex: '#fffcff', //8
        count: 0,
        type: 'o',
      },
      {
        hex: '#fcffff', //9
        count: 0,
        type: 'pripa',
      },
      {
        hex: '#fffffb', //10
        count: 0,
        type: 'secpa',
      },
      {
        hex: '#696969', //11
        count: 0,
        type: 'blpa',
      },
      {
        hex: '#fbffff', //12
        count: 0,
        type: 'whpa',
      },
      {
        hex: '#fffffa', //13
        count: 0,
        type: 'o',
      },
      {
        hex: '#fffaff', //14
        count: 0,
        type: 'o',
      },
      {
        hex: '#faffff', //15
        count: 0,
        type: 'o',
      },
      {
        hex: '#fffff9', //16
        count: 0,
        type: 'o',
      },
      {
        hex: '#fff9ff', //17
        count: 0,
        type: 'pripa',
      },
      {
        hex: '#f9ffff', //18
        count: 0,
        type: 'secpa',
      },
      {
        hex: '#808080', //19
        count: 0,
        type: 'blpa',
      },
      {
        hex: '#fff8ff', //20
        count: 0,
        type: 'whpa',
      },
      {
        hex: '#f8ffff', //21
        count: 0,
        type: 'o',
      },
      {
        hex: '#fffff7', //22
        count: 0,
        type: 'o',
      },
      {
        hex: '#fff7ff', //23
        count: 0,
        type: 'o',
      },
      {
        hex: '#f7ffff', //24
        count: 0,
        type: 'o',
      },
    ],

    homescreen: [
      {
        image: {
          src: '',
        },
        text: '',
        textColor: '',
        cta_url: '',
        cta: '',
        cta_type: '',
      },
    ],
    locations: [],
  })

  useEffect(() => {
    let newState = state
    let business = JSON.parse(localStorage.getItem('business'))
    let _temp = []

    business.map((node, i) => {
      let singleLocation = {}
      // console.log(node.employees, 'node')
      singleLocation.location_settings = {
        // employee_mode: 'lite',
        default: false,
        home: { home_label: 'Home' },
        staff: {
          staff_label: 'Our Barbers',
          staff_mode: 'lite',
          color: '#c11d2e',
          hidden: true,
        },
        about: { aboutus_label: 'Our Mission', color: '#c11d2e', hidden: true },
        service: { service_label: 'Services', color: '#c11d2e', hidden: true },
        gallery: {
          gallery_label: 'Gallery',
          logo: '',
          color: '#c11d2e',
          hidden: true,
        },
        hours: { hours_label: 'Hours', color: '#c11d2e', hidden: true },
        reviews: { review_label: 'Reviews', color: '#c11d2e', hidden: true },
      }
      singleLocation.region = node.info.state
      singleLocation.locationName = node.info.name
      singleLocation.address = node.info.address
      singleLocation.city = node.info.city
      singleLocation.state = node.info.state
      singleLocation.zip = node.info.zipcode
      singleLocation.phone = node.info.phone
      singleLocation.business_id = node.business.business_code
      singleLocation.app_id = node.info.appointmentBusinessId
      singleLocation.external_url = ''
      singleLocation.hero_image = ''
      singleLocation.individual_page = {
        homeimage: {
          src: '',
        },
        services: node.info.servicecategories,
        employee: [],
        gallery: [
          {
            image: {
              src: '',
            },
            caption: '',
          },
        ],
        socialmedia: [],
        about_us: {
          image: {
            src: '',
          },
          paragraph: '',
        },
        hours: [],
        reviews: [
          {
            customer_id: 123,
            customer_name: '',
            review: '',
            rating: '0',
            customer_pic: {
              src: '',
            },
          },
        ],
      }
      if (node.employees && node.employees.length > 0)
        node.employees.map((eachEmp, j) => {
          let emp = {
            employee_id: eachEmp.id,
            name: eachEmp.full_name,
            title: eachEmp.role,
            bio: '',
            phone: '',
            email: '',
            profile_image: {
              src: '',
            },
            social_media: [
              {
                type: 'facebook',
                url: 'www.facebook.com',
                icon_url: 'url',
              },
              {
                type: 'instagram',
                url: 'www.facebook.com',
                icon_url: 'url',
                handle: '@uzeli',
              },
            ],
          }

          singleLocation.individual_page.employee.push(emp)
        })
      if (node.business.offce_hours) {
        let hours = JSON.parse(node.business.offce_hours)
        let _hours = Object.keys(hours)
        const pushHour = key => {
          let _tempObj = {
            day: '',
            closed: false,
            open: '',
            close: '',
          }

          _tempObj.day = key
          _tempObj.closed = false
          _tempObj.open = `${moment(hours[key].open, 'HH').format('hh:mm a')}`
          _tempObj.close = `${moment(hours[key].close, 'HH').format('hh:mm a')}`
          singleLocation.individual_page.hours.push(_tempObj)
        }

        _hours.map(key => {
          if (
            key == 'sunday' ||
            key == 'monday' ||
            key == 'tuesday' ||
            key == 'wednesday' ||
            key == 'thursday' ||
            key == 'friday' ||
            key == 'saturday'
          )
            pushHour(key)
          else {
            console.log(hours.hours[0])
            let _h = [
              {
                day: 'sunday',
                closed: false,
                open: `${moment(hours.hours[0].start, 'HH:mm').format('hh:mm a')}`,
                close: `${moment(hours.hours[0].end, 'HH:mm').format('hh:mm a')}`,
              },
              {
                day: 'monday',
                closed: false,
                open: `${moment(hours.hours[0].start, 'HH:mm').format('hh:mm a')}`,
                close: `${moment(hours.hours[0].end, 'HH:mm').format('hh:mm a')}`,
              },
              {
                day: 'tuesday',
                closed: false,
                open: `${moment(hours.hours[0].start, 'HH:mm').format('hh:mm a')}`,
                close: `${moment(hours.hours[0].end, 'HH:mm').format('hh:mm a')}`,
              },
              {
                day: 'wednesday',
                closed: false,
                open: `${moment(hours.hours[0].start, 'HH:mm').format('hh:mm a')}`,
                close: `${moment(hours.hours[0].end, 'HH:mm').format('hh:mm a')}`,
              },
              {
                day: 'thursday',
                closed: false,
                open: `${moment(hours.hours[0].start, 'HH:mm').format('hh:mm a')}`,
                close: `${moment(hours.hours[0].end, 'HH:mm').format('hh:mm a')}`,
              },
              {
                day: 'friday',
                closed: false,
                open: `${moment(hours.hours[0].start, 'HH:mm').format('hh:mm a')}`,
                close: `${moment(hours.hours[0].end, 'HH:mm').format('hh:mm a')}`,
              },
              {
                day: 'saturday',
                closed: false,
                open: `${moment(hours.hours[0].start, 'HH:mm').format('hh:mm a')}`,
                close: `${moment(hours.hours[0].end, 'HH:mm').format('hh:mm a')}`,
              },
            ]
            singleLocation.individual_page.hours = _h
          }
        })
      }
      _temp.push(singleLocation)
    })

    newState.locations = _temp
    console.log(newState, 'actions zeffect')
    actions({ type: 'setState', payload: { ...newState } })
  }, [])

  const actions = action => {
    const { type, payload } = action
    console.log(state, 'actions')
    switch (type) {
      case 'setState':
        return setState(payload)
      default:
        return state
    }
  }
  return { state, actions }
}

export default useGlobalStore
