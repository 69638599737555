import React, { useState, useContext, useEffect } from 'react'
import { Upload, Modal, Input, Mentions, AutoComplete, Button, Tag } from 'antd'
import {
  uploaderImage,
  getImage,
  getTags,
  updaterImage,
  getdeleteImage,
  updateName,
  updateTag,
  deleteTag,
} from '../../api/index'

import { FiEdit2 } from 'react-icons/fi'

import Masonry from 'react-masonry-component'
import _ from 'lodash'

const Container = props => {
  const masonryOptions = {
    transitionDuration: 0,
    columnWidth: 1,
  }
  const className = props.classData
  const [modalVisible, setmodalVisible] = useState(false)
  const [currentImage, setcurrentImage] = useState([])
  const [currentSrc, setcurrentSrc] = useState()
  const [imageName, setimageName] = useState('')
  const [value, setValue] = useState('')
  const [allOptions, setallOptions] = useState([])
  const [deletePanel, setdeletePanel] = useState(false)
  const [indexDelete, setindexDelete] = useState(0)

  const [options, setOptions] = useState([])
  const [tag, settag] = useState([])
  const [img, setImg] = useState([])
  const [ButtonUpdate, setButtonUpdate] = useState(false)
  const [UpdateImage, setUpdateImage] = useState(null)
  const [imageRow, setimageRow] = useState(0)

  const onChangeUpload = async file => {
    props.changeOverlay(true)
    // console.log(file, 'uploadeed')
    setButtonUpdate(false)
    let temp = file.file.originFileObj.name.split('.')
    setcurrentImage(file.file)
    setimageName(temp[0])
    let src = file.url

    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file.file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    // console.log(src, 'source for data')
    setcurrentSrc(src)
    onUpload(file.file)
  }

  const onDisplayNameChange = e => {
    // console.log(e.target.value, 'value')
    setimageName(e.target.value)
  }

  const onUpload = file => {
    // console.log('Uploading...........', file)
    let temp = {
      file: file.originFileObj,
      display_name: file.name,
      path: 'env',
      meta: 'many',
      type: 'image',
      size: file.size,
    }
    // console.log(temp, 'currentImage')
    let myfile = new FormData()
    for (var key in temp) {
      myfile.append(key, temp[key])
    }
    currentImage.details = temp

    uploaderImage(myfile)
      .then(res => {
        // console.log(res, 'response after uploaderImage')
        if (res.statusText == 'OK') {
          // console.log(res.data, 'temp')
          let fileList = {}
          fileList.id = res.data.id
          fileList.display_name = res.data.display_name
          fileList.meta = res.data.meta
          fileList.path = res.data.path
          fileList.type = res.data.type
          fileList.row_id = res.data.row_id
          fileList.tag = res.data.tag
          setimageRow(res.data.row_id)
          props.update(fileList)
          setValue('')
          // console.log(fileList, 'fileLists')
          // settag([])
          // setmodalVisible(false)
          props.changeOverlay(false)
          setmodalVisible(true)
        } else {
          console.log('error')
        }
      })
      .catch(err => console.log(err))
  }

  const onCancelUpload = () => {
    // props.remove(currentImage)
    setmodalVisible(false)
    // setcurrentSrc('')
    // setValue('')
    // settag([])
  }

  const onSearch = searchText => {
    let temp = []
    allOptions.map((node, i) => {
      if (
        node.value.toLowerCase().match(searchText.toLowerCase()) &&
        searchText.length > 2
      ) {
        temp.push(node)
        // console.log(node, 'tag selected')
      }
    })
    setOptions(temp)
  }

  const onSelect = data => {
    // console.log('onSelect', data)
  }

  const onChange = data => {
    setValue(data)
  }

  const addTag = () => {
    let temp = tag ? tag : []
    let details = {}
    let tagObj = {}
    // console.log(tag, 'tag')

    if (value.trim() != '') {
      let index = _.findIndex(allOptions, ['value', value])
      if (index != -1) {
        tagObj = allOptions[index]
      } else {
        tagObj.value = value
        tagObj.type = 'misc'
      }
      tagObj.media_id = imageRow
      details.tags = JSON.stringify(tagObj)
      let myfile = new FormData()
      for (var key in details) {
        myfile.append(key, details[key])
      }
      console.log(details, 'data after tag')
      updateTag(myfile).then(data => {
        // console.log(data.data, 'datas')
        temp.push(data.data)
        settag([...temp])
        props.updateTag(temp, imageRow)
      })
      setValue('')
      setOptions([])
    }
  }

  const closeTag = i => {
    let temp = tag
    let details = { tags: JSON.stringify(temp[i]) }
    // console.log(details, 'close tag')
    let myfile = new FormData()
    for (var key in details) {
      myfile.append(key, details[key])
    }

    deleteTag(myfile).then(data => {
      temp.splice(i, 1)
      settag([...temp])
      // console.log(data, 'data after tag delet')
    })

    setOptions([])
  }

  useEffect(() => {}, [tag])

  useEffect(() => {
    console.log(props, 'added props see the props')
    setImg([...props.fileList])
  }, [props.fileList])

  useEffect(() => {
    getTags('H').then(data => {
      // console.log(data.data, 'data all of services from cloud is ok')
      let tempOptions = []
      Promise.all(
        data.data.data.map((node, i) => {
          let temp = {}
          temp.value = node.name
          temp.label = node.type
          tempOptions.push(temp)
        }),
      )
        .then(() => {
          var flags = [],
            output = [],
            l = tempOptions.length,
            i
          for (i = 0; i < l; i++) {
            if (flags[tempOptions[i].value]) continue
            flags[tempOptions[i].value] = true
            output.push({ value: tempOptions[i].value, type: tempOptions[i].label })
          }
          // console.log(output)

          setallOptions(output)
        })
        .then(() => {
          // console.log(allOptions)
        })
    })
    setImg([...props.fileList])
  }, [])

  const editImage = node => {
    // console.log(node, "image's details")
    let temp = node.id.split('.')
    let imageid = temp[0]
    let imagedis = node.display_name + '.' + temp[1]
    console.log(node, 'nodes')
    setUpdateImage(node)
    setButtonUpdate(true)
    setimageRow(node.row_id)
    settag(node.tag)
    setimageName(node.display_name)
    setcurrentSrc(
      `${process.env.MOBILE_APP_BACKEND_URL}/getimage/medium/${imageid}/${node.path}/${imagedis}`,
    )
    setmodalVisible(true)
  }

  const onUpdate = () => {
    let temp = UpdateImage
    temp.display_name = imageName
    temp.tag = tag
    let myfile = new FormData()
    for (var key in temp) {
      myfile.append(key, temp[key])
    }
    updaterImage(myfile).then(data => {
      setmodalVisible(false)
    })
  }
  const deleteImage = () => {
    let temp = UpdateImage
    temp.tag = JSON.stringify(temp.tag)
    let myfile = new FormData()
    for (var key in temp) {
      myfile.append(key, temp[key])
    }
    // console.log(temp, 'updated image')
    getdeleteImage(myfile).then(data => {
      let _temp = img
      _temp.splice(indexDelete, 1)
      props.updateAll(_temp)
      setmodalVisible(false)
    })
  }

  const updatenamebtn = i => {
    let temp = {}
    temp.imagerow_id = imageRow
    temp.display_name = imageName
    let myfile = new FormData()
    for (var key in temp) {
      myfile.append(key, temp[key])
    }
    let _tempimg = img
    updateName(myfile).then(data => {
      _tempimg.map((node, i) => {
        if (node.row_id == imageRow) {
          _tempimg[i].display_name = imageName
        }
      })

      props.updateAll(_tempimg)
      // console.log(data, 'data after updating name')
    })
  }

  let Panel = () => {
    if (deletePanel) {
      return (
        <div className="shouldDelete">
          <div className="shouldDeleteNo" onClick={() => setdeletePanel(false)}>
            No
          </div>
          <div
            className="shouldDeleteTrue"
            onClick={() => {
              deleteImage()
              setdeletePanel(false)
            }}
          >
            Yes
          </div>
        </div>
      )
    } else {
      return <div></div>
    }
  }
  return (
    <>
      <div>
        <Masonry options={masonryOptions}>
          <Upload
            listType="picture-card"
            onChange={file => onChangeUpload(file)}
            showUploadList={false}
            className={className}
            fileList={[]}
          >
            + Upload
          </Upload>
          {img.length > 0 &&
            img.map((node, i) => {
              if (node.id != undefined || node.id != null) {
                let _arr = node.id.split('.')
                let _uid = _arr[0]
                let _dis = node.display_name + '.' + _arr[1]

                return (
                  <div key={node.id} style={{ margin: '10px' }}>
                    <img
                      src={`${process.env.MOBILE_APP_BACKEND_URL}/getimage/small/${_uid}/${node.path}/${_dis}`}
                    />
                    <div
                      className="logouploader_edit"
                      onClick={() => {
                        // console.log(node)
                        editImage(node)
                        setindexDelete(i)
                      }}
                    >
                      <FiEdit2 />
                    </div>
                  </div>
                )
              }
            })}
        </Masonry>
      </div>
      {/* <Button key="back" onClick={() => onCancelUpload()}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            //onClick={() => (ButtonUpdate ? onUpdate() : onUpload())}
            onClick={() => onCancelUpload()}
          >
            Ok
          </Button>,
          , */}
      <Modal
        visible={modalVisible}
        closable={true}
        maskClosable={true}
        onCancel={() => onCancelUpload()}
        destroyOnClose={true}
        title="Details"
        footer={[
          <Button
            key="submit"
            type="primary"
            //onClick={() => (ButtonUpdate ? onUpdate() : onUpload())}
            onClick={() => onCancelUpload()}
          >
            Ok
          </Button>,
          <Button
            danger
            type="primary"
            onClick={() => {
              setdeletePanel(true)
            }}
            style={{ float: 'left' }}
          >
            Delete
          </Button>,
          <Panel />,
        ]}
      >
        <div>
          <Input
            addonAfter={
              <p
                onClick={() => updatenamebtn()}
                style={{ margin: '0', cursor: 'pointer' }}
              >
                {' '}
                Save
              </p>
            }
            placeholder="Display name"
            value={imageName}
            onChange={onDisplayNameChange}
          ></Input>

          <div>
            <br></br>
            Tags:
            {console.log(tag)}
            {tag &&
              tag.length > 0 &&
              tag.map((node, i) => {
                // console.log(node)
                return (
                  <>
                    {node != undefined && (
                      <Tag key={node.value} closable onClose={() => closeTag(i)}>
                        {node.value}
                      </Tag>
                    )}
                  </>
                )
              })}
          </div>
          <br></br>
          <AutoComplete
            value={value}
            options={options}
            style={{
              width: 200,
            }}
            onSelect={onSelect}
            onChange={onChange}
            onSearch={text => onSearch(text)}
            placeholder="Enter the tags"
          ></AutoComplete>
          <Button type="primary" onClick={() => addTag()}>
            Add tag
          </Button>
        </div>
        <br></br>

        <img src={currentSrc} style={{ width: '100%' }} />
      </Modal>
    </>
  )
}

export default Container
