import React, { useEffect, useState, useContext } from 'react'

import Cropper from '../logo/cropper'
import MarkDown from 'markdown-to-jsx'
import ImageGallery from '../imagegallery'
import MarkDownComponent from '../markdown/markdowneditor'
import { Modal, Button } from 'antd'
import Slider from 'react-slick'
import Context from '../../store/context'
import SimpleMDE from 'react-simplemde-editor'
import { FaTrashAlt } from 'react-icons/fa'
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai'
import LocTabs from '../previewtab/locationtabs'
const LocationGallery = props => {
  const { state, actions } = useContext(Context)
  console.log('wow')
  const [homeState, setHomeState] = useState({
    modalvisible: false,
    Modalname: '',
    SelectedImage: '',
    width: 0,
    height: 0,
    slidenumber: 0,
    ImageFor: '',
    shouldDelete: false,
  })
  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  }

  const fieldData = {
    image: {
      src: '',
    },
    caption: '',
  }

  const addHomeScreen = () => {
    console.log(state, 'state after added')
    let temp = state
    temp.locations[props.data.locationIndex].individual_page.gallery.push(fieldData)
    actions({ type: 'setState', payload: { ...temp } })
  }

  const imageClicked = (node, uid, display_name) => {
    console.log(state, 'after temp')
    setHomeState({
      ...homeState,
      SelectedImage: node,
      Modalname: 'Cropper',
      selectedImgId: uid,
      selectedName: display_name,
    })
  }

  const editImage = node => {
    let h, w, x, y, id, display_name
    let dim = node.getCroppingRect()
    h = dim.height.toString() //.replace('.', '-')
    w = dim.width.toString() //.replace('.', '-')
    x = dim.x.toString() //.replace('.', '-')
    y = dim.y.toString() //.replace('.', '-')
    id = homeState.selectedImgId.split('.')
    display_name = homeState.selectedName
    if (homeState.ImageFor === 'Background') {
      let temp = state

      temp.locations[props.data.locationIndex].individual_page.gallery[
        homeState.slidenumber
      ].image.src = `${process.env.MOBILE_APP_BACKEND_URL}/getcroppedimage/${h}/${w}/${x}/${y}/env/${id[0]}/${display_name}.${id[1]}` //node.getImageScaledToCanvas().toDataURL()
      actions({ type: 'setState', payload: { ...temp } })
    } else if (homeState.ImageFor === 'Logo') {
      let temp = contextState
      temp.logo.src = `${process.env.MOBILE_APP_BACKEND_URL}/getcroppedimage/${h}/${w}/${x}/${y}/env/${id[0]}/${display_name}.${id[1]}`

      actions({ type: 'setState', payload: { ...temp } })
    }

    setHomeState({ ...homeState, Modalname: '', modalvisible: false })
  }

  const handleMDChange = e => {
    let temp = state
    temp.locations[props.data.locationIndex].individual_page.gallery[
      homeState.slidenumber
    ].caption = e
    actions({ type: 'setState', payload: { ...temp } })
  }

  const deleteSlide = i => {
    let temp = state
    temp.locations[props.data.locationIndex].individual_page.gallery.splice(i, 1)
    actions({ type: 'setState', payload: { ...temp } })
    setHomeState({ ...homeState, slidenumber: i, shouldDelete: false })
  }

  const onLeft = i => {
    let temp = state
    if (i != 0) {
      let leftTemp =
        temp.locations[props.data.locationIndex].individual_page.gallery[i]
      temp.locations[props.data.locationIndex].individual_page.gallery[i] =
        temp.locations[props.data.locationIndex].individual_page.gallery[i - 1]
      temp.locations[props.data.locationIndex].individual_page.gallery[
        i - 1
      ] = leftTemp
      actions({ type: 'setState', payload: { ...temp } })
    }
  }

  const onRight = i => {
    let temp = state
    if (i < temp.homescreen.length - 1) {
      let rightTemp =
        temp.locations[props.data.locationIndex].individual_page.gallery[i]
      temp.locations[props.data.locationIndex].individual_page.gallery[i] =
        temp.locations[props.data.locationIndex].individual_page.gallery[i + 1]
      temp.locations[props.data.locationIndex].individual_page.gallery[
        i + 1
      ] = rightTemp
      actions({ type: 'setState', payload: { ...temp } })
    }
  }

  useEffect(() => {}, [homeState])

  return (
    <div>
      <Slider {...settings}>
        {state.hasOwnProperty('locations') &&
          state.locations.length > 0 &&
          state.locations[props.data.locationIndex].hasOwnProperty(
            'individual_page',
          ) &&
          state.locations[props.data.locationIndex].individual_page.gallery.map(
            (node, i) => {
              return (
                <div className="homescreen" key={i}>
                  <div className="hs_phone hs_phone_outer">
                    <div className="frame-notch"></div>
                    <div className="loc_gal_upload">
                      {state &&
                      state.hasOwnProperty('locations') &&
                      state.locations.length > 0 &&
                      state.locations[props.data.locationIndex].hasOwnProperty(
                        'individual_page',
                      ) &&
                      state.locations[props.data.locationIndex].individual_page
                        .gallery[i].image.src ? (
                        <img
                          onClick={() => {
                            setHomeState({
                              ...homeState,
                              Modalname: 'Image Gallery',
                              slidenumber: i,
                              width: 300,
                              height: 400,
                              ImageFor: 'Background',
                              modalvisible: true,
                            })
                          }}
                          width="256px"
                          src={
                            state.locations[props.data.locationIndex].individual_page
                              .gallery[i].image.src
                          }
                        />
                      ) : (
                        <div
                          className="upload_img"
                          onClick={() => {
                            setHomeState({
                              ...homeState,
                              Modalname: 'Image Gallery',
                              slidenumber: i,
                              width: 300,
                              height: 400,
                              ImageFor: 'Background',
                              modalvisible: true,
                            })
                          }}
                        >
                          <h4>+ Upload image</h4>
                        </div>
                      )}
                      <div className="loc_gal_number"></div>
                      <div
                        className="loc_gal_logo"
                        style={
                          state &&
                          state.hasOwnProperty('locations') &&
                          state.locations.length > 0 &&
                          state.locations[props.data.locationIndex].hasOwnProperty(
                            'individual_page',
                          ) &&
                          state.locations[props.data.locationIndex].individual_page
                            .gallery[i].image.src
                            ? { top: '-260px' }
                            : { top: '220px' }
                        }
                      >
                        {state.logo.src ? <img src={state.logo.src} /> : 'Logo'}
                      </div>
                      <div
                        className="loc_gal_text"
                        style={
                          state &&
                          state.hasOwnProperty('locations') &&
                          state.locations.length > 0 &&
                          state.locations[props.data.locationIndex].hasOwnProperty(
                            'individual_page',
                          ) &&
                          state.locations[props.data.locationIndex].individual_page
                            .gallery[i].image.src
                            ? { top: '-260px' }
                            : { top: '220px' }
                        }
                        onClick={() => {
                          setHomeState({
                            ...homeState,
                            Modalname: 'Caption',
                            slidenumber: i,

                            modalvisible: true,
                          })
                        }}
                      >
                        {state &&
                        state.hasOwnProperty('locations') &&
                        state.locations.length > 0 &&
                        state.locations[props.data.locationIndex].hasOwnProperty(
                          'individual_page',
                        ) &&
                        state.locations[props.data.locationIndex].individual_page
                          .gallery[i].caption ? (
                          <h2>
                            {
                              state.locations[props.data.locationIndex]
                                .individual_page.gallery[i].caption
                            }
                          </h2>
                        ) : (
                          <h2>Enter your Caption</h2>
                        )}
                      </div>
                    </div>
                    {/* <LocTabs /> */}
                    <div
                      className="delete_button loc_gal_suredel"
                      onClick={() => {
                        setHomeState({
                          ...homeState,
                          slidenumber: i,
                          shouldDelete: true,
                        })
                      }}
                    >
                      <FaTrashAlt />
                    </div>
                    {homeState.shouldDelete && homeState.slidenumber == i ? (
                      <div className="sure_delete loc_gal_suredel">
                        <div
                          className="delete_yes"
                          onClick={() => {
                            setHomeState({ ...homeState, shouldDelete: false })

                            deleteSlide(i)
                          }}
                        >
                          <p>Delete</p>
                        </div>
                        <div
                          className="delete_no"
                          onClick={() => {
                            setHomeState({
                              ...homeState,
                              shouldDelete: false,
                              slidenumber: i,
                            })
                          }}
                        >
                          <p>Cancel</p>
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                    <div className="hs_numbering">
                      <AiFillCaretLeft onClick={() => onLeft(i)} />
                      {i + 1}
                      <AiFillCaretRight onClick={() => onRight(i)} />
                    </div>
                  </div>
                </div>
              )
            },
          )}

        <div className="add_hs" onClick={() => addHomeScreen()}>
          <div className="homescreen">
            <div className="hs_phone hs_phone_outer">
              <div className="frame-notch"> </div>
              <div className="hs_upload">
                <div className="add_slide">
                  <h2>Add Slide</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
      <Modal
        title={homeState.Modalname}
        visible={homeState.modalvisible}
        onOk={() => {
          {
            /* console.log('OK') */
          }
          setHomeState({ ...homeState, modalvisible: false, Modalname: '' })
        }}
        onCancel={() => {
          {
            /* console.log('Cancel') */
          }
          setHomeState({ ...homeState, modalvisible: false, Modalname: '' })
        }}
        destroyOnClose={true}
        footer={
          homeState.Modalname === 'Cropper'
            ? null
            : [
                <Button
                  onClick={() =>
                    setHomeState({
                      ...homeState,
                      modalvisible: false,
                      Modalname: '',
                    })
                  }
                >
                  Cancel
                </Button>,
                <Button
                  type="primary"
                  onClick={() =>
                    setHomeState({
                      ...homeState,
                      modalvisible: false,
                      Modalname: '',
                    })
                  }
                >
                  Ok
                </Button>,
              ]
        }
      >
        {console.log(homeState.Modalname, 'at modal')}
        {homeState.Modalname === 'Image Gallery' ||
        homeState.Modalname === 'Image Gallery for logo' ? (
          <ImageGallery imageClicked={imageClicked} />
        ) : homeState.Modalname === 'Cropper' ? (
          <Cropper
            image={homeState.SelectedImage}
            width={homeState.width}
            height={homeState.height}
            edited={editImage}
          />
        ) : homeState.Modalname === 'Caption' ? (
          // <MarkDownComponent
          //   handleChange={handleMDChange}
          //   slide={homeState.slidenumber}
          // />
          <SimpleMDE
            onChange={e => {
              handleMDChange(e)
            }}
          />
        ) : null}
      </Modal>
    </div>
  )
}

export default LocationGallery
