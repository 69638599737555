export const getUser = () => {
    return JSON.parse(localStorage.getItem("user")) || null ;
}

export const getBusiness = () => {
    return JSON.parse(localStorage.getItem("business")) || []
}


export const getBusinessById = (id) => {
    return getBusiness().filter(b => {
        return b.business.id === parseInt(id)
    })[0].business
}