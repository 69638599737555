import React from 'react'
import ReactDom from 'react-dom'

class AppPortal extends React.Component {
  el

  constructor(props) {
    super(props)
    this.el = document.createElement('div')
    this.el.className = 'col-lg-12 main-box'
    this.el.style.width = '100%'
    this.el.style.height = '100%'
    // console.log(el, 'element')
  }

  componentDidMount() {
    const { portalRootEl } = this.props

    portalRootEl.innerHTML = ''
    portalRootEl.appendChild(this.el)
  }

  // may be unnecessary
  componentDidUpdate(prevProps) {
    const { portalRootEl } = this.props

    if (prevProps.portalRootEl !== portalRootEl) {
      portalRootEl.appendChild(this.el)
    }
  }

  componentWillUnmount() {
    const { portalRootEl } = this.props
    try {
      portalRootEl.removeChild(this.el)
    } catch (e) {
      console.error('cannot unmount', e)
    }
  }

  render() {
    const { children } = this.props

    return ReactDom.createPortal(children, this.el)
  }
}

export default AppPortal
