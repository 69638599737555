import React, { useState, useContext, useEffect } from 'react'
import PrimarySettings from '../setting/primarysetting/primarysetting'
import SimpleMDE from 'react-simplemde-editor'
import 'easymde/dist/easymde.min.css'
import Context from '../../store/context'
import { Tooltip } from 'antd'
const MarkDownComponent = props => {
  const option = {}
  const [from, setFrom] = useState(0)
  const { state, actions } = useContext(Context)
  const onReady = instance => {
    // console.log(instance.value)
  }

  const setallColor = color => {
    console.log(from, 'froms is 0')
    let temp = state
    temp.homescreen[props.slide].textColor = color.hex
    let isSame = false
    let isIndex = 0

    state.color.map((node, i) => {
      if (node.hex == color.hex) {
        temp.color[i].count++
        isSame = true
        isIndex = i
      }
    })

    if (!isSame && from == 0) {
      for (let i = 0; i < state.color.length; i++) {
        if (temp.color[i].type == 'o' && temp.color[i].count == 0) {
          temp.color[i].hex = color.hex
          temp.color[i].count++
          setFrom(i)
          console.log(from, 'froms')
          break
        }
      }
    } else if (!isSame) {
      console.log('from')
      temp.color[from].hex = color.hex
    }

    actions({ type: 'setState', payload: { ...temp } })
  }

  return (
    <>
      <div className="markdown_settings">
        <Tooltip placement="top" title="Secondary Color">
          <div>
            <PrimarySettings
              value={setallColor}
              mycolor={state.homescreen[props.slide].textColor}
              width={'10px'}
              height={'10px'}
            />
          </div>
        </Tooltip>
      </div>
      <SimpleMDE
        option={option}
        value={state.homescreen[props.slide].text}
        onChange={e => {
          props.handleChange(e)
        }}
      />
      <div className="markdown_settings">
        {/* <p>
          Markdown Color: <PrimarySettings />
        </p> */}
      </div>
    </>
  )
}

export default MarkDownComponent
