import React from 'react'
import { FiHome, FiMapPin, FiUser } from 'react-icons/fi'
import './tabs.css'
const Tabs = () => {
  return (
    <div className="mytabs loc_mytabs absolute">
      <div className="row">
        <div className="col-4 col-sm-4 col-md-4 col-lg-4 mx-auto myTabCol">
          <div className="eachTab nobottom" style={{ borderRadius: '0 0 0 30px' }}>
            <div>
              <FiHome />
              <p>Home</p>
              {/* <div className="bottom"></div> */}
            </div>
          </div>
        </div>
        <div className="col-4 col-sm-4 col-md-4 col-lg-4 mx-auto myTabCol">
          <div className="eachTab ">
            <div>
              <FiMapPin />
              <p>Location</p>
              <div className="bottom"></div>
            </div>
          </div>
        </div>
        <div className="col-4 col-sm-4 col-md-4 col-lg-4 mx-auto myTabCol">
          <div className="eachTab nobottom" style={{ borderRadius: '0 0 30px 0' }}>
            <div>
              <FiUser />
              <p>Account</p>
              {/* <div className="nobottom"></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tabs
