import React, { useEffect, useState, useContext } from 'react'
import LocTabs from '../previewtab/locationtabs'
import Context from '../../store/context'
import { AiOutlineRight } from 'react-icons/ai'
import Slider from 'react-slick'
import { Modal, Button } from 'antd'
import SimpleMDE from 'react-simplemde-editor'
import Cropper from '../logo/cropper'
import MarkDown from 'markdown-to-jsx'
import ImageGallery from '../imagegallery'
import StarRatings from 'react-star-ratings'
import { FaTrashAlt } from 'react-icons/fa'
const LocationReview = props => {
  const { state, actions } = useContext(Context)

  const [homeState, setHomeState] = useState({
    modalvisible: false,
    Modalname: '',
    SelectedImage: '',
    width: 0,
    height: 0,
    slidenumber: 0,
    ImageFor: '',
    shouldDelete: false,
  })

  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  }

  const addReview = () => {
    let tempState = state
    let temp = {
      customer_id: 123,
      customer_name: '',
      review: '',
      rating: '0',
      customer_pic: {
        src: '',
      },
    }

    tempState.locations[props.data.locationIndex].individual_page.reviews.push(temp)

    actions({ type: 'setState', payload: { ...tempState } })
  }

  const imageClicked = (node, uid, display_name) => {
    // console.log(state, 'after temp')
    setHomeState({
      ...homeState,
      SelectedImage: node,
      Modalname: 'Cropper',
      selectedImgId: uid,
      selectedName: display_name,
    })
  }

  const editImage = node => {
    let h, w, x, y, id, display_name
    let dim = node.getCroppingRect()
    h = dim.height.toString() //.replace('.', '-')
    w = dim.width.toString() //.replace('.', '-')
    x = dim.x.toString() //.replace('.', '-')
    y = dim.y.toString() //.replace('.', '-')
    id = homeState.selectedImgId.split('.')
    display_name = homeState.selectedName
    if (homeState.ImageFor === 'Background') {
      let temp = state

      temp.locations[props.data.locationIndex].individual_page.reviews[
        homeState.slidenumber
      ].customer_pic.src = `${process.env.MOBILE_APP_BACKEND_URL}/getcroppedimage/${h}/${w}/${x}/${y}/env/${id[0]}/${display_name}.${id[1]}` //node.getImageScaledToCanvas().toDataURL()
      actions({ type: 'setState', payload: { ...temp } })
    } else if (homeState.ImageFor === 'Logo') {
      let temp = contextState
      temp.logo.src = `${process.env.MOBILE_APP_BACKEND_URL}/getcroppedimage/${h}/${w}/${x}/${y}/env/${id[0]}/${display_name}.${id[1]}`

      actions({ type: 'setState', payload: { ...temp } })
    }

    setHomeState({ ...homeState, Modalname: '', modalvisible: false })
  }

  const handleMDChange = e => {
    let temp = state
    temp.locations[props.data.locationIndex].individual_page.reviews[
      homeState.slidenumber
    ].review = e
    actions({ type: 'setState', payload: { ...temp } })
  }

  const handleMDNameChange = e => {
    let temp = state
    temp.locations[props.data.locationIndex].individual_page.reviews[
      homeState.slidenumber
    ].customer_name = e
    actions({ type: 'setState', payload: { ...temp } })
  }

  const changeRating = (r, i) => {
    let temp = state
    temp.locations[props.data.locationIndex].individual_page.reviews[i].rating = r

    actions({ type: 'setState', payload: { ...temp } })
  }

  const deleteSlide = i => {
    let temp = state
    temp.locations[props.data.locationIndex].individual_page.reviews.splice(i, 1)
    actions({ type: 'setState', payload: { ...temp } })
    setHomeState({ ...homeState, slidenumber: i, shouldDelete: false })
  }

  useEffect(() => {}, [homeState])

  return (
    <>
      <div>
        <Slider {...settings}>
          <div className="homescreen ">
            <div className="hs_phone hs_phone_outer loc_bar_outer">
              <div className="frame-notch"> </div>
              <div className="loc_home_main">
                <div
                  className="loc_bar_main"
                  style={{
                    height: 'auto',
                    minHeight: '410px',
                    paddingBottom: '20px',
                    backgroundColor:
                      state &&
                      state.hasOwnProperty('locations') &&
                      state.locations.length > 0 &&
                      state.locations[props.data.locationIndex].hasOwnProperty(
                        'individual_page',
                      ) &&
                      state.locations[props.data.locationIndex].location_settings
                        .reviews.color,
                  }}
                >
                  {state.locations[
                    props.data.locationIndex
                  ].individual_page.reviews.map((node, i) => {
                    return (
                      <div className="loc_rev_card" key={i}>
                        <div className="loc_rev_top">
                          <div
                            className="loc_rev_pro"
                            onClick={() => {
                              setHomeState({
                                ...homeState,
                                Modalname: 'Image Gallery',
                                width: 300,
                                height: 300,
                                ImageFor: 'Background',
                                slidenumber: i,
                                modalvisible: true,
                              })
                            }}
                          >
                            {node.customer_pic.src ? (
                              <img src={node.customer_pic.src} />
                            ) : (
                              <h4>+</h4>
                            )}
                          </div>
                          <div className="loc_rev_name">
                            <h3
                              onClick={() => {
                                setHomeState({
                                  ...homeState,
                                  Modalname: 'Edit Name',
                                  slidenumber: i,
                                  modalvisible: true,
                                })
                              }}
                            >
                              {node.customer_name ? node.customer_name : 'Add Name'}
                            </h3>
                            <StarRatings
                              rating={parseInt(node.rating)}
                              starRatedColor={
                                state &&
                                state.hasOwnProperty('locations') &&
                                state.locations.length > 0 &&
                                state.locations[
                                  props.data.locationIndex
                                ].hasOwnProperty('individual_page') &&
                                state.locations[props.data.locationIndex]
                                  .location_settings.reviews.color
                              }
                              starHoverColor={
                                state &&
                                state.hasOwnProperty('locations') &&
                                state.locations.length > 0 &&
                                state.locations[
                                  props.data.locationIndex
                                ].hasOwnProperty('individual_page') &&
                                state.locations[props.data.locationIndex]
                                  .location_settings.reviews.color
                              }
                              numberOfStars={5}
                              name="rating"
                              starDimension="20px"
                              starSpacing="2px"
                              isSelectable={true}
                              changeRating={r => {
                                changeRating(r, i)
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="loc_rev_bio"
                          onClick={() => {
                            setHomeState({
                              ...homeState,
                              Modalname: 'Edit Bio',
                              slidenumber: i,
                              modalvisible: true,
                            })
                          }}
                        >
                          {node.review ? node.review : 'Edit the review'}
                        </div>
                        <div
                          className="loc_rev_btn"
                          onClick={() => {
                            setHomeState({
                              ...homeState,
                              slidenumber: i,
                              shouldDelete: true,
                            })
                          }}
                        >
                          <FaTrashAlt />
                        </div>
                        {homeState.shouldDelete && homeState.slidenumber == i ? (
                          <div className="loc_rev_del">
                            <div
                              className="delete_yes"
                              onClick={() => {
                                setHomeState({ ...homeState, shouldDelete: false })

                                deleteSlide(i)
                              }}
                            >
                              <p>Delete</p>
                            </div>
                            <div
                              className="delete_no"
                              onClick={() => {
                                setHomeState({
                                  ...homeState,
                                  shouldDelete: false,
                                  slidenumber: i,
                                })
                              }}
                            >
                              <p>Cancel</p>
                            </div>
                          </div>
                        ) : (
                          <p></p>
                        )}
                      </div>
                    )
                  })}

                  <div className="loc_rev_add" onClick={() => addReview()}>
                    <h4>+ Add Review</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
        <Modal
          title={homeState.Modalname}
          visible={homeState.modalvisible}
          onOk={() => {
            {
              /* console.log('OK') */
            }
            setHomeState({ ...homeState, modalvisible: false, Modalname: '' })
          }}
          onCancel={() => {
            {
              /* console.log('Cancel') */
            }
            setHomeState({ ...homeState, modalvisible: false, Modalname: '' })
          }}
          destroyOnClose={true}
          footer={
            homeState.Modalname === 'Cropper'
              ? null
              : [
                  <Button
                    onClick={() =>
                      setHomeState({
                        ...homeState,
                        modalvisible: false,
                        Modalname: '',
                      })
                    }
                  >
                    Cancel
                  </Button>,
                  <Button
                    type="primary"
                    onClick={() =>
                      setHomeState({
                        ...homeState,
                        modalvisible: false,
                        Modalname: '',
                      })
                    }
                  >
                    Ok
                  </Button>,
                ]
          }
        >
          {homeState.Modalname === 'Image Gallery' ||
          homeState.Modalname === 'Image Gallery for logo' ? (
            <ImageGallery imageClicked={imageClicked} />
          ) : homeState.Modalname === 'Cropper' ? (
            <Cropper
              image={homeState.SelectedImage}
              width={homeState.width}
              height={homeState.height}
              edited={editImage}
            />
          ) : homeState.Modalname === 'Edit Bio' ? (
            // <MarkDownComponent
            //   handleChange={handleMDChange}
            //   slide={homeState.slidenumber}
            // />
            <SimpleMDE
              onChange={e => {
                handleMDChange(e)
              }}
            />
          ) : homeState.Modalname === 'Edit Name' ? (
            <SimpleMDE
              onChange={e => {
                handleMDNameChange(e)
              }}
            />
          ) : null}
        </Modal>
      </div>
    </>
  )
}

export default LocationReview
