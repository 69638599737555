import React, { useEffect, useState, useContext } from 'react'
import LocTabs from '../previewtab/locationtabs'
import Context from '../../store/context'
import { AiOutlineRight } from 'react-icons/ai'
import Slider from 'react-slick'
import { Modal, Button } from 'antd'
import SimpleMDE from 'react-simplemde-editor'
import Cropper from '../logo/cropper'
import MarkDown from 'markdown-to-jsx'
import ImageGallery from '../imagegallery'
const LocationMission = props => {
  const { state, actions } = useContext(Context)
  // console.log(state, 'state from barber')
  const [homeState, setHomeState] = useState({
    modalvisible: false,
    Modalname: '',
    SelectedImage: '',
    width: 0,
    height: 0,
    slidenumber: 0,
    ImageFor: '',
    shouldDelete: false,
  })

  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  }

  const imageClicked = (node, uid, display_name) => {
    // console.log(state, 'after temp')
    setHomeState({
      ...homeState,
      SelectedImage: node,
      Modalname: 'Cropper',
      selectedImgId: uid,
      selectedName: display_name,
    })
  }

  const editImage = node => {
    let h, w, x, y, id, display_name
    let dim = node.getCroppingRect()
    h = dim.height.toString() //.replace('.', '-')
    w = dim.width.toString() //.replace('.', '-')
    x = dim.x.toString() //.replace('.', '-')
    y = dim.y.toString() //.replace('.', '-')
    id = homeState.selectedImgId.split('.')
    display_name = homeState.selectedName
    if (homeState.ImageFor === 'Background') {
      let temp = state

      temp.locations[
        props.data.locationIndex
      ].individual_page.about_us.image.src = `${process.env.MOBILE_APP_BACKEND_URL}/getcroppedimage/${h}/${w}/${x}/${y}/env/${id[0]}/${display_name}.${id[1]}` //node.getImageScaledToCanvas().toDataURL()
      actions({ type: 'setState', payload: { ...temp } })
    } else if (homeState.ImageFor === 'Logo') {
      let temp = contextState
      temp.logo.src = `${process.env.MOBILE_APP_BACKEND_URL}/getcroppedimage/${h}/${w}/${x}/${y}/env/${id[0]}/${display_name}.${id[1]}`

      actions({ type: 'setState', payload: { ...temp } })
    }

    setHomeState({ ...homeState, Modalname: '', modalvisible: false })
  }

  const handleMDChange = e => {
    let temp = state
    temp.locations[props.data.locationIndex].individual_page.about_us.paragraph = e
    actions({ type: 'setState', payload: { ...temp } })
  }

  useEffect(() => {}, [homeState])

  return (
    <>
      <div>
        <Slider {...settings}>
          <div className="homescreen ">
            <div className="hs_phone hs_phone_outer ">
              <div className="frame-notch"> </div>
              {/* <div className="loc_home_main"> */}
              <div
                className="loc_miss_main"
                style={{
                  backgroundColor:
                    state &&
                    state.hasOwnProperty('locations') &&
                    state.locations.length > 0 &&
                    state.locations[props.data.locationIndex].hasOwnProperty(
                      'individual_page',
                    ) &&
                    state.locations[props.data.locationIndex].location_settings.about
                      .color,
                }}
              >
                <div className="loc_miss_profile">
                  {state &&
                  state.hasOwnProperty('locations') &&
                  state.locations.length > 0 &&
                  state.locations[props.data.locationIndex].hasOwnProperty(
                    'individual_page',
                  ) &&
                  state.locations[props.data.locationIndex].individual_page.about_us
                    .image.src ? (
                    <img
                      onClick={() => {
                        setHomeState({
                          ...homeState,
                          Modalname: 'Image Gallery',

                          width: 300,
                          height: 400,
                          ImageFor: 'Background',
                          modalvisible: true,
                        })
                      }}
                      src={
                        state.locations[props.data.locationIndex].individual_page
                          .about_us.image.src
                      }
                      style={{ width: '100%', height: '100%' }}
                    />
                  ) : (
                    <div
                      className="upload_img"
                      onClick={() => {
                        // console.log('clicked')
                        setHomeState({
                          ...homeState,
                          Modalname: 'Image Gallery',

                          width: 300,
                          height: 400,
                          ImageFor: 'Background',
                          modalvisible: true,
                        })
                      }}
                    >
                      <h4>+ Upload image</h4>
                    </div>
                  )}
                </div>
                <div className="loc_miss_bio">
                  <h2>Who are we?</h2>

                  <br />
                  <div
                    onClick={() => {
                      setHomeState({
                        ...homeState,
                        Modalname: 'Edit Bio',

                        modalvisible: true,
                      })
                    }}
                  >
                    {state.locations[props.data.locationIndex].individual_page
                      .about_us.paragraph ? (
                      <p style={{ textAlign: 'left', padding: '10px' }}>
                        {
                          state.locations[props.data.locationIndex].individual_page
                            .about_us.paragraph
                        }
                      </p>
                    ) : (
                      <p style={{ textAlign: 'center' }}>Enter the Bio</p>
                    )}
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </Slider>
        <Modal
          title={homeState.Modalname}
          visible={homeState.modalvisible}
          onOk={() => {
            {
              /* console.log('OK') */
            }
            setHomeState({ ...homeState, modalvisible: false, Modalname: '' })
          }}
          onCancel={() => {
            {
              /* console.log('Cancel') */
            }
            setHomeState({ ...homeState, modalvisible: false, Modalname: '' })
          }}
          destroyOnClose={true}
          footer={
            homeState.Modalname === 'Cropper'
              ? null
              : [
                  <Button
                    onClick={() =>
                      setHomeState({
                        ...homeState,
                        modalvisible: false,
                        Modalname: '',
                      })
                    }
                  >
                    Cancel
                  </Button>,
                  <Button
                    type="primary"
                    onClick={() =>
                      setHomeState({
                        ...homeState,
                        modalvisible: false,
                        Modalname: '',
                      })
                    }
                  >
                    Ok
                  </Button>,
                ]
          }
        >
          {/* {console.log(homeState.Modalname, 'at modal')} */}
          {homeState.Modalname === 'Image Gallery' ||
          homeState.Modalname === 'Image Gallery for logo' ? (
            <ImageGallery imageClicked={imageClicked} />
          ) : homeState.Modalname === 'Cropper' ? (
            <Cropper
              image={homeState.SelectedImage}
              width={homeState.width}
              height={homeState.height}
              edited={editImage}
            />
          ) : homeState.Modalname === 'Edit Bio' ? (
            // <MarkDownComponent
            //   handleChange={handleMDChange}
            //   slide={homeState.slidenumber}
            // />
            <SimpleMDE
              onChange={e => {
                handleMDChange(e)
              }}
            />
          ) : null}
        </Modal>
      </div>
    </>
  )
}

export default LocationMission
