import React, { useState, useContext, useEffect } from 'react'
import { Modal, Button } from 'antd'
import ImageGallery from '../imagegallery'
import MarkDownComponent from '../markdown/markdowneditor'
// import MarkDown from 'react-markdown/with-html'
import MarkDown from 'markdown-to-jsx'
import './phone.css'
import Tabs from '../previewtab/tabs'
import Cropper from '../logo/cropper'
import { FaTrashAlt } from 'react-icons/fa'
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai'
import Slider from 'react-slick'
import Context from '../../store/context'
// import CodeBlock from './codeblock'
const AllHomeScreen = () => {
  const { state, actions } = useContext(Context)

  const [homeState, setHomeState] = useState({
    modalvisible: false,
    Modalname: '',
    SelectedImage: '',
    width: 0,
    height: 0,
    slidenumber: 0,
    ImageFor: '',
    shouldDelete: false,
  })

  const fieldData = {
    image: {
      src: '',
    },
    text: '',
    textColor: '',
    cta_url: '',
    cta: '',
    cta_type: '',
  }

  const imageClicked = (node, uid, display_name) => {
    setHomeState({
      ...homeState,
      SelectedImage: node,
      Modalname: 'Cropper',
      selectedImgId: uid,
      selectedName: display_name,
    })
  }

  const editImage = node => {
    console.log(
      'edited',
      homeState.selectedImgId,
      homeState.SelectedImage,
      node.getImage(),
      node.getCroppingRect(),
    )
    let h, w, x, y, id, display_name
    let dim = node.getCroppingRect()
    h = dim.height.toString() //.replace('.', '-')
    w = dim.width.toString() //.replace('.', '-')
    x = dim.x.toString() //.replace('.', '-')
    y = dim.y.toString() //.replace('.', '-')
    id = homeState.selectedImgId.split('.')
    display_name = homeState.selectedName
    if (homeState.ImageFor === 'Background') {
      let temp = state
      temp.homescreen[
        homeState.slidenumber
      ].image.src = `${process.env.MOBILE_APP_BACKEND_URL}/getcroppedimage/${h}/${w}/${x}/${y}/env/${id[0]}/${display_name}.${id[1]}` //node.getImageScaledToCanvas().toDataURL()
      actions({ type: 'setState', payload: { ...temp } })
    } else if (homeState.ImageFor === 'Logo') {
      let temp = state
      temp.logo.src = `${process.env.MOBILE_APP_BACKEND_URL}/getcroppedimage/${h}/${w}/${x}/${y}/env/${id[0]}/${display_name}.${id[1]}`
      actions({ type: 'setState', payload: { ...temp } })
    }

    setHomeState({ ...homeState, Modalname: '', modalvisible: false })
  }

  const addHomeScreen = () => {
    let temp = state
    temp.homescreen.push(fieldData)
    actions({ type: 'setState', payload: { ...temp } })
  }

  const handleMDChange = e => {
    let temp = state
    temp.homescreen[homeState.slidenumber].text = e
    actions({ type: 'setState', payload: { ...temp } })
  }

  const handleMDColor = e => {
    let temp = state
    temp.homescreen[homeState.slidenumber].textColor = e
    actions({ type: 'setState', payload: { ...temp } })
  }

  useEffect(() => {}, [homeState])

  const deleteSlide = i => {
    let temp = state
    temp.homescreen.splice(i, 1)
    actions({ type: 'setState', payload: { ...temp } })
    setHomeState({ ...homeState, slidenumber: i, shouldDelete: false })
  }
  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  }

  const onLeft = i => {
    let temp = state
    if (i != 0) {
      let leftTemp = temp.homescreen[i]
      temp.homescreen[i] = temp.homescreen[i - 1]
      temp.homescreen[i - 1] = leftTemp
      actions({ type: 'setState', payload: { ...temp } })
    }
  }

  const onRight = i => {
    let temp = state
    if (i < temp.homescreen.length - 1) {
      let rightTemp = temp.homescreen[i]
      temp.homescreen[i] = temp.homescreen[i + 1]
      temp.homescreen[i + 1] = rightTemp
      actions({ type: 'setState', payload: { ...temp } })
    }
  }

  return (
    <>
      <Slider {...settings}>
        {state.homescreen.map((node, i) => {
          return (
            <div key={i}>
              <div className="homescreen">
                <div className="hs_phone hs_phone_outer">
                  <div className="frame-notch"> </div>
                  <div
                    className="hs_upload"
                    onClick={() => {
                      setHomeState({
                        ...homeState,
                        Modalname: 'Image Gallery',
                        width: 300,
                        height: 400,
                        ImageFor: 'Background',
                        slidenumber: i,
                        modalvisible: true,
                      })
                      {
                        /* console.log(homeState, 'homestate') */
                      }
                    }}
                  >
                    {node.image.src ? (
                      <img src={node.image.src} />
                    ) : (
                      <div>
                        <h4>+ Upload image</h4>
                      </div>
                    )}
                  </div>
                  <div
                    className="hs_logo_uploader"
                    onClick={() => {
                      setHomeState({
                        ...homeState,
                        Modalname: 'Image Gallery',
                        width: 300,
                        height: 300,
                        ImageFor: 'Logo',
                        modalvisible: true,
                        slidenumber: i,
                      })
                    }}
                  >
                    {state.logo.src ? (
                      <img src={state.logo.src} />
                    ) : (
                      <div>
                        <h4>+ Upload logo</h4>
                      </div>
                    )}
                  </div>
                  <div className="hs_markdown">
                    <div
                      onClick={() => {
                        setHomeState({
                          ...homeState,
                          slidenumber: i,
                          modalvisible: true,
                          Modalname: 'Slide Text (*supports markdown)',
                        })
                      }}
                    >
                      {node.text ? (
                        <MarkDown
                          children={node.text}
                          options={{
                            createElement(type, props, children) {
                              return (
                                <div>
                                  {React.createElement(
                                    type,
                                    { style: { color: node.textColor } },
                                    children,
                                  )}
                                </div>
                              )
                            },
                          }}
                        />
                      ) : (
                        <h2>Edit your text here</h2>
                      )}
                    </div>
                  </div>
                  <div className="ctn_button">
                    <button>{`Book Now `}&#8250;</button>
                  </div>
                  <Tabs />
                  <div
                    className="delete_button"
                    onClick={() => {
                      setHomeState({
                        ...homeState,
                        slidenumber: i,
                        shouldDelete: true,
                      })
                    }}
                  >
                    <FaTrashAlt />
                  </div>
                  {homeState.shouldDelete && homeState.slidenumber == i ? (
                    <div className="sure_delete">
                      <div
                        className="delete_yes"
                        onClick={() => {
                          setHomeState({ ...homeState, shouldDelete: false })

                          deleteSlide(i)
                        }}
                      >
                        <p>Delete</p>
                      </div>
                      <div
                        className="delete_no"
                        onClick={() => {
                          setHomeState({
                            ...homeState,
                            shouldDelete: false,
                            slidenumber: i,
                          })
                        }}
                      >
                        <p>Cancel</p>
                      </div>
                    </div>
                  ) : (
                    <p></p>
                  )}
                  <div className="hs_numbering">
                    <AiFillCaretLeft onClick={() => onLeft(i)} />
                    {i + 1}
                    <AiFillCaretRight onClick={() => onRight(i)} />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        <div className="add_hs" onClick={() => addHomeScreen()}>
          <div className="homescreen">
            <div className="hs_phone hs_phone_outer">
              <div className="frame-notch"> </div>
              <div className="hs_upload">
                <div className="add_slide">
                  <h2>Add Slide</h2>
                </div>
              </div>
              <Tabs />
            </div>
          </div>
        </div>
      </Slider>
      <Modal
        title={homeState.Modalname}
        visible={homeState.modalvisible}
        onOk={() => {
          {
            /* console.log('OK') */
          }
          setHomeState({ ...homeState, modalvisible: false, Modalname: '' })
        }}
        onCancel={() => {
          {
            /* console.log('Cancel') */
          }
          setHomeState({ ...homeState, modalvisible: false, Modalname: '' })
        }}
        destroyOnClose={true}
        footer={
          homeState.Modalname === 'Cropper'
            ? null
            : [
                <Button
                  onClick={() =>
                    setHomeState({
                      ...homeState,
                      modalvisible: false,
                      Modalname: '',
                    })
                  }
                >
                  Cancel
                </Button>,
                <Button
                  type="primary"
                  onClick={() =>
                    setHomeState({
                      ...homeState,
                      modalvisible: false,
                      Modalname: '',
                    })
                  }
                >
                  Ok
                </Button>,
              ]
        }
      >
        {homeState.Modalname === 'Slide Text (*supports markdown)' ? (
          <MarkDownComponent
            handleChange={handleMDChange}
            slide={homeState.slidenumber}
          />
        ) : homeState.Modalname === 'Image Gallery' ||
          homeState.Modalname === 'Image Gallery for logo' ? (
          <ImageGallery imageClicked={imageClicked} />
        ) : homeState.Modalname === 'Cropper' ? (
          <Cropper
            image={homeState.SelectedImage}
            width={homeState.width}
            height={homeState.height}
            edited={editImage}
          />
        ) : null}
      </Modal>
      <Modal></Modal>
    </>
  )
}

export default AllHomeScreen
