import React, { useState, useEffect } from 'react'
import HomeScreen from './homescreen/homescreen'
import { Tabs } from 'antd'
import ImageUploader from './logo/logouploader'
import { FilesProvider } from './context/filescontext'
import Settings from './setting/settings'
import { GiPencilBrush } from 'react-icons/gi'
import { IoMdImages } from 'react-icons/io'
import { FiSettings } from 'react-icons/fi'
import { getImage, getAllImages } from '../api/index'
import Context from '../store/context'
import useGlobalStore from '../store/globalState'
import LoadingOverlay from 'react-loading-overlay'

const Container = () => {
  const store = useGlobalStore()

  const [isActive, setisActive] = useState(false)

  const { TabPane } = Tabs

  function callback(key) {}
  const [fileList, setFileList] = useState([])
  const onChange = file => {}

  const updateImage = details => {
    // console.log(details, 'details in conatiner after upload')
    setFileList([...fileList, details])
  }

  const updateAll = files => {
    setFileList([...files])
  }

  const onRemove = file => {
    // console.log(file, fileList, 'file in container')
    let temp = fileList
    temp.map((node, i) => {
      if (node.uid == file.uid) {
        fileList.splice(i, 1)
      }
    })
    setFileList(temp)
  }

  const updateTag = (arrTag, imageRow) => {
    let lists = fileList
    lists.map((node, i) => {
      if (node.row_id == imageRow) {
        lists[i].tag = arrTag
        setFileList([...lists])
        console.log(lists, 'array tag')
      }
    })
  }

  const changeOverlay = value => {
    setisActive(value)
  }

  useEffect(() => {}, [fileList])

  useEffect(() => {
    getAllImages('env')
      .then(data => {
        // console.log(data, 'all images')
        setFileList([...data.data])
      })
      .catch(err => console.log(err, 'err'))
  }, [])

  return (
    <>
      {/* {console.log(fileList, 'data from getalls in data')} */}
      <Context.Provider value={store}>
        <LoadingOverlay active={isActive} spinner text="Working">
          <Tabs onChange={callback} type="card" className="container_tab">
            <TabPane
              tab={
                <span>
                  <GiPencilBrush />
                  Design
                </span>
              }
              key="1"
            >
              <FilesProvider value={fileList}>
                <HomeScreen />
              </FilesProvider>
            </TabPane>
            <TabPane
              tab={
                <span>
                  <IoMdImages />
                  Images
                </span>
              }
              key="2"
            >
              <ImageUploader
                classData="all_images"
                altData="Upload background image"
                onChange={onChange}
                fileList={fileList}
                remove={onRemove}
                update={updateImage}
                updateAll={updateAll}
                updateTag={updateTag}
                changeOverlay={changeOverlay}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <FiSettings />
                  Settings
                </span>
              }
              key="3"
            >
              <Settings />
            </TabPane>
          </Tabs>
        </LoadingOverlay>
      </Context.Provider>
    </>
  )
}

export default Container
