import React, { useState, useContext, useEffect } from 'react'
import { Button } from 'antd'
import AvatarEditor from 'react-avatar-editor'
const Cropper = props => {
  // console.log(props, 'Cropper props')
  const [editor, setEditorRef] = useState()
  const onClickSave = () => {
    // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
    // drawn on another canvas, or added to the DOM.
    // console.log('hello')
    const canvas = editor.getImage()

    // If you want the image resized to the canvas size (also a HTMLCanvasElement)
  }

  return (
    <>
      <AvatarEditor
        crossOrigin="anonymous"
        ref={ref => setEditorRef(ref)}
        image={props.image}
        width={props.width}
        height={props.height}
        border={50}
      />
      <Button type="primary" onClick={() => props.edited(editor)}>
        Done!
      </Button>
    </>
  )
}

export default Cropper
