import React, { useEffect, useState, useContext } from 'react'
import { Menu } from 'antd'
import { ShopOutlined } from '@ant-design/icons'
import Context from '../../store/context'
import { FiSettings } from 'react-icons/fi'
import { Modal, Input, Button, Switch, Checkbox } from 'antd'

import PrimarySettings from '../setting/primarysetting/primarysetting'
const { SubMenu } = Menu
const LocationMenu = props => {
  // console.log(props, 'props')
  const { state, actions } = useContext(Context)
  const [effect, seteffect] = useState(false)
  const [allbiz, setallbiz] = useState([])
  const [from, setFrom] = useState(0)
  const [menudata, setmenudata] = useState({
    locationIndex: 0,
    locationMenu: 'home',
    subMenu: null,
    modalVisible: false,
  })

  const [locationSetting, setlocationStetting] = useState({
    default: true,
    home: { home_label: 'Home' },
    staff: {
      staff_label: 'Our Barbers',
      staff_mode: 'lite',
      color: '',
      hidden: true,
    },
    about: { aboutus_label: 'Our Mission', color: '', hidden: true },
    service: { service_label: 'Services', color: '', hidden: true },
    gallery: { gallery_label: 'Gallery', logo: '', hidden: true },
    hours: { hours_label: 'Hours', color: '', hidden: true },
    reviews: { review_label: 'Reviews', color: '', hidden: true },
  })
  useEffect(() => {}, [effect])

  const menuClicked = (where, i, j) => {
    props.data(where, i, j)
    let temp = {}
    temp.locationIndex = i
    temp.locationMenu = where
    temp.subMenu = j
    setmenudata({ ...temp })
  }

  const settingClicked = i => {
    setmenudata({ ...menudata, modalVisible: true })
    let temp = state
    setlocationStetting({ ...temp.locations[i].location_settings })
  }

  const changeLabel = (changefor, event) => {
    // console.log('label checkec')
    seteffect(!effect)
    let temp = state
    let _temp = state.locations[menudata.locationIndex].location_settings
    let e = event.target.value
    switch (changefor) {
      case 'services':
        _temp.service.service_label = e
        break
      case 'gallery':
        _temp.gallery.gallery_label = e
        break
      case 'barbers':
        _temp.staff.staff_label = e
        break
      case 'hours':
        _temp.hours.hours_label = e
        break
      case 'mission':
        _temp.about.aboutus_label = e
        break
      case 'reviews':
        _temp.reviews.review_label = e
        break
      default:
        break
    }

    if (state.locations[menudata.locationIndex].location_settings.default) {
      setDefault()
    } else {
      temp.locations[menudata.locationIndex].location_settings = _temp
    }
    setlocationStetting({ ..._temp })
  }

  const changeSwitch = (changefor, e) => {
    seteffect(!effect)
    let temp = state
    let _temp = temp.locations[menudata.locationIndex].location_settings
    switch (changefor) {
      case 'services':
        _temp.service.hidden = e
        break
      case 'gallery':
        _temp.gallery.hidden = e
        break
      case 'barbers':
        _temp.staff.hidden = e
        break
      case 'hours':
        _temp.hours.hidden = e
        break
      case 'mission':
        _temp.about.hidden = e
        break
      case 'reviews':
        _temp.reviews.hidden = e
        break
      default:
        break
    }

    if (state.locations[menudata.locationIndex].location_settings.default) {
      setDefault()
    } else {
      temp.locations[menudata.locationIndex].location_settings = _temp
      actions({ type: 'setState', payload: { ...temp } })
    }
  }

  const saveLables = () => {
    let temp = state
    setFrom(0)

    setmenudata({ ...menudata, modalVisible: false })
  }

  const setallColor = color => {
    console.log('called once')
    let temp = state
    // temp.locations[menudata.locationIndex].location_settings
    // temp.homescreen[props.slide].textColor = color.hex

    let isSame = false
    let isIndex = 0

    state.color.map((node, i) => {
      if (node.hex == color.hex) {
        console.log('Same color')
        temp.color[i].count++
        isSame = true
        isIndex = i
      }
    })

    if (!isSame && from == 0) {
      for (let i = 0; i < state.color.length; i++) {
        if (temp.color[i].type == 'o' && temp.color[i].count == 0) {
          console.log('U')
          temp.color[i].hex = color.hex
          temp.color[i].count++
          setFrom(i)

          // console.log(temp.color[i], from, 'froms')
          break
        }
      }
    } else if (!isSame) {
      console.log('from')
      temp.color[from].hex = color.hex
    }
    // console.log(temp.color, 'temp')

    actions({ type: 'setState', payload: { ...temp } })
  }

  const setOtherColor = colors => {
    let color = colors.hex
    let temp = state
    let _temp = temp.locations[menudata.locationIndex].location_settings
    switch (menudata.locationMenu) {
      case 'services':
        _temp.service.color = color
        setallColor(colors)
        break
      case 'gallery':
        _temp.gallery.color = color
        setallColor(colors)
        break
      case 'barbers':
        _temp.staff.color = color
        setallColor(colors)
        break
      case 'hours':
        _temp.hours.color = color
        setallColor(colors)
        break
      case 'mission':
        _temp.about.color = color
        setallColor(colors)
        break
      case 'reviews':
        _temp.reviews.color = color
        setallColor(colors)
        break
      default:
        break
    }
    actions({ type: 'setState', payload: { ...temp } })
  }

  const setDefault = () => {
    // props.data(where, i, j)
    let temp = state
    let _temp = temp.locations[menudata.locationIndex].location_settings

    state.locations.map((node, i) => {
      temp.locations[i].location_settings.service.service_label =
        _temp.service.service_label
      temp.locations[i].location_settings.service.hidden = _temp.service.hidden
      temp.locations[i].location_settings.gallery.gallery_label =
        _temp.gallery.gallery_label
      temp.locations[i].location_settings.gallery.hidden = _temp.gallery.hidden
      temp.locations[i].location_settings.staff.staff_label = _temp.staff.staff_label
      temp.locations[i].location_settings.staff.hidden = _temp.staff.hidden
      temp.locations[i].location_settings.hours.hours_label = _temp.hours.hours_label
      temp.locations[i].location_settings.hours.hidden = _temp.hours.hidden
      temp.locations[i].location_settings.about.aboutus_label =
        _temp.about.aboutus_label
      temp.locations[i].location_settings.about.hidden = _temp.about.hidden
      temp.locations[i].location_settings.reviews.review_label =
        _temp.reviews.review_label
      temp.locations[i].location_settings.reviews.hidden = _temp.reviews.hidden
    })
    actions({ type: 'setState', payload: { ...temp } })
  }
  const defaultCheck = e => {
    let temp = state

    temp.locations.map((node, i) => {
      node.location_settings.default = e.target.checked //true or false
    })

    if (state.locations[menudata.locationIndex].location_settings.default) {
      setDefault()
    }

    seteffect(!effect)
  }
  return (
    <div>
      <Menu
        style={{ width: 200 }}
        mode="inline"
        defaultSelectedKeys={[`home 0`]}
        defaultOpenKeys={['0']}
      >
        {state &&
          state.locations.map((node, i) => {
            // console.log(node.location_settings.service, 'node after the node')
            return (
              <SubMenu key={i} icon={<ShopOutlined />} title={node.locationName}>
                <Menu.Item key={`home ${i}`} onClick={() => menuClicked('home', i)}>
                  {node.location_settings.home.home_label}
                </Menu.Item>
                {menudata.locationIndex == i && menudata.locationMenu == 'home' ? (
                  <div className="menu_settings" onClick={() => settingClicked(i)}>
                    <FiSettings />
                  </div>
                ) : null}

                {node.location_settings.service.hidden && (
                  <Menu.Item
                    key={`services ${i}`}
                    onClick={() => menuClicked('services', i)}
                  >
                    {node.location_settings.service.service_label}
                  </Menu.Item>
                )}
                {menudata.locationIndex == i &&
                menudata.locationMenu == 'services' ? (
                  <div className="menu_settings" onClick={() => settingClicked(i)}>
                    <FiSettings />
                  </div>
                ) : null}
                {node.location_settings.gallery.hidden && (
                  <Menu.Item
                    key={`gallery ${i}`}
                    onClick={() => menuClicked('gallery', i)}
                  >
                    {node.location_settings.gallery.gallery_label}
                  </Menu.Item>
                )}
                {menudata.locationIndex == i &&
                menudata.locationMenu == 'gallery' ? (
                  <div className="menu_settings" onClick={() => settingClicked(i)}>
                    <FiSettings />
                  </div>
                ) : null}
                {/* <Menu.Item key={`barbers ${i}`}>Our Barbers</Menu.Item> */}

                {menudata.locationIndex == i &&
                menudata.locationMenu == 'barbers' ? (
                  <div
                    className="menu_settings menu_set_barber"
                    onClick={() => settingClicked(i)}
                  >
                    <FiSettings />
                  </div>
                ) : null}

                {node.location_settings.staff.hidden && (
                  <SubMenu
                    key={`barbers ${i}`}
                    title={node.location_settings.staff.staff_label}
                  >
                    {node.individual_page.employee &&
                      node.individual_page.employee.map((staff, j) => {
                        return (
                          <Menu.Item
                            key={`barbers ${j}`}
                            onClick={() => menuClicked('barbers', i, j)}
                          >
                            {staff.name}
                          </Menu.Item>
                        )
                      })}
                  </SubMenu>
                )}
                {node.location_settings.hours.hidden && (
                  <Menu.Item
                    key={`hours ${i}`}
                    onClick={() => menuClicked('hours', i)}
                  >
                    {node.location_settings.hours.hours_label}
                  </Menu.Item>
                )}
                {menudata.locationIndex == i && menudata.locationMenu == 'hours' ? (
                  <div className="menu_settings" onClick={() => settingClicked(i)}>
                    <FiSettings />
                  </div>
                ) : null}
                {node.location_settings.about.hidden && (
                  <Menu.Item
                    key={`mission ${i}`}
                    onClick={() => menuClicked('mission', i)}
                  >
                    {node.location_settings.about.aboutus_label}
                  </Menu.Item>
                )}
                {menudata.locationIndex == i &&
                menudata.locationMenu == 'mission' ? (
                  <div className="menu_settings" onClick={() => settingClicked(i)}>
                    <FiSettings />
                  </div>
                ) : null}
                {node.location_settings.reviews.hidden && (
                  <Menu.Item
                    key={`reviews ${i}`}
                    onClick={() => menuClicked('reviews', i)}
                  >
                    {node.location_settings.reviews.review_label}
                  </Menu.Item>
                )}
                {menudata.locationIndex == i &&
                menudata.locationMenu == 'reviews' ? (
                  <div className="menu_settings" onClick={() => settingClicked(i)}>
                    <FiSettings />
                  </div>
                ) : null}
              </SubMenu>
            )
          })}
      </Menu>
      <Modal
        visible={menudata.modalVisible}
        title="Setting"
        onOk={() => {
          saveLables()
        }}
        onCancel={() => {
          setmenudata({ ...menudata, modalVisible: false })
          setFrom(0)
        }}
        destroyOnClose={true}
      >
        {menudata.locationMenu === 'home' ? (
          <div className="set_each_inputs">
            <br />
            {state &&
              state.hasOwnProperty('locations') &&
              state.locations.length > 0 &&
              state.locations[menudata.locationIndex].hasOwnProperty(
                'individual_page',
              ) && (
                <div className="set_flex_inputs">
                  <Input
                    addonBefore="Services"
                    onChange={e => changeLabel('services', e)}
                    disabled={
                      !state.locations[menudata.locationIndex].location_settings
                        .service.hidden
                    }
                    value={
                      state.locations[menudata.locationIndex].location_settings
                        .service.service_label
                    }
                  />
                  <Switch
                    defaultChecked={
                      state.locations[menudata.locationIndex].location_settings
                        .service.hidden
                    }
                    onChange={e => changeSwitch('services', e)}
                  />
                </div>
              )}

            <br />

            {state &&
              state.hasOwnProperty('locations') &&
              state.locations.length > 0 &&
              state.locations[menudata.locationIndex].hasOwnProperty(
                'individual_page',
              ) && (
                <div className="set_flex_inputs">
                  <Input
                    addonBefore="Gallery"
                    onChange={e => changeLabel('gallery', e)}
                    disabled={
                      !state.locations[menudata.locationIndex].location_settings
                        .gallery.hidden
                    }
                    value={
                      state.locations[menudata.locationIndex].location_settings
                        .gallery.gallery_label
                    }
                  />
                  <Switch
                    defaultChecked={
                      state.locations[menudata.locationIndex].location_settings
                        .gallery.hidden
                    }
                    onChange={e => changeSwitch('gallery', e)}
                  />
                </div>
              )}

            <br />
            {state &&
              state.hasOwnProperty('locations') &&
              state.locations.length > 0 &&
              state.locations[menudata.locationIndex].hasOwnProperty(
                'individual_page',
              ) && (
                <div className="set_flex_inputs">
                  <Input
                    addonBefore="Barbers"
                    onChange={e => changeLabel('barbers', e)}
                    disabled={
                      !state.locations[menudata.locationIndex].location_settings
                        .staff.hidden
                    }
                    value={
                      state.locations[menudata.locationIndex].location_settings.staff
                        .staff_label
                    }
                  />
                  <Switch
                    defaultChecked={
                      state.locations[menudata.locationIndex].location_settings.staff
                        .hidden
                    }
                    onChange={e => changeSwitch('barbers', e)}
                  />
                </div>
              )}

            <br />
            {state &&
              state.hasOwnProperty('locations') &&
              state.locations.length > 0 &&
              state.locations[menudata.locationIndex].hasOwnProperty(
                'individual_page',
              ) && (
                <div className="set_flex_inputs">
                  <Input
                    addonBefore="Hours"
                    onChange={e => changeLabel('hours', e)}
                    disabled={
                      !state.locations[menudata.locationIndex].location_settings
                        .hours.hidden
                    }
                    value={
                      state.locations[menudata.locationIndex].location_settings.hours
                        .hours_label
                    }
                  />
                  <Switch
                    defaultChecked={
                      state.locations[menudata.locationIndex].location_settings.hours
                        .hidden
                    }
                    onChange={e => changeSwitch('hours', e)}
                  />
                </div>
              )}

            <br />
            {state &&
              state.hasOwnProperty('locations') &&
              state.locations.length > 0 &&
              state.locations[menudata.locationIndex].hasOwnProperty(
                'individual_page',
              ) && (
                <div className="set_flex_inputs">
                  <Input
                    addonBefore="Our Mission"
                    onChange={e => changeLabel('mission', e)}
                    disabled={
                      !state.locations[menudata.locationIndex].location_settings
                        .about.hidden
                    }
                    value={
                      state.locations[menudata.locationIndex].location_settings.about
                        .aboutus_label
                    }
                  />
                  <Switch
                    defaultChecked={
                      state.locations[menudata.locationIndex].location_settings.about
                        .hidden
                    }
                    onChange={e => changeSwitch('mission', e)}
                  />
                </div>
              )}

            <br />
            {state &&
              state.hasOwnProperty('locations') &&
              state.locations.length > 0 &&
              state.locations[menudata.locationIndex].hasOwnProperty(
                'individual_page',
              ) && (
                <div className="set_flex_inputs">
                  <Input
                    addonBefore="Reviews"
                    onChange={e => changeLabel('reviews', e)}
                    disabled={
                      !state.locations[menudata.locationIndex].location_settings
                        .reviews.hidden
                    }
                    value={
                      state.locations[menudata.locationIndex].location_settings
                        .reviews.review_label
                    }
                  />
                  <Switch
                    defaultChecked={
                      state.locations[menudata.locationIndex].location_settings
                        .reviews.hidden
                    }
                    onChange={e => changeSwitch('reviews', e)}
                  />
                </div>
              )}

            <br />
            {state &&
              state.hasOwnProperty('locations') &&
              state.locations.length > 0 &&
              state.locations[menudata.locationIndex].hasOwnProperty(
                'individual_page',
              ) && (
                <div>
                  <Checkbox
                    checked={
                      state.locations[menudata.locationIndex].location_settings
                        .default
                    }
                    onChange={e => defaultCheck(e)}
                  >
                    Default for all locations
                  </Checkbox>
                </div>
              )}
          </div>
        ) : menudata.locationMenu == 'services' ? (
          <>
            Select Color:
            <PrimarySettings
              value={setOtherColor}
              mycolor="#ffffff"
              width={'50px'}
              height={'50px'}
            />
          </>
        ) : menudata.locationMenu == 'gallery' ? (
          <>
            Select Color:
            <PrimarySettings
              value={setOtherColor}
              mycolor="#ffffff"
              width={'50px'}
              height={'50px'}
            />
          </>
        ) : menudata.locationMenu == 'barbers' ? (
          <>
            Select Color:
            <PrimarySettings
              value={setOtherColor}
              mycolor={
                state &&
                state.hasOwnProperty('locations') &&
                state.locations.length > 0 &&
                state.locations[menudata.locationIndex].hasOwnProperty(
                  'individual_page',
                ) &&
                state.locations[menudata.locationIndex].location_settings.staff.color
              }
              width={'50px'}
              height={'50px'}
            />
          </>
        ) : menudata.locationMenu == 'hours' ? (
          <>
            Select Color:
            <PrimarySettings
              value={setOtherColor}
              mycolor={
                state &&
                state.hasOwnProperty('locations') &&
                state.locations.length > 0 &&
                state.locations[menudata.locationIndex].hasOwnProperty(
                  'individual_page',
                ) &&
                state.locations[menudata.locationIndex].location_settings.hours.color
              }
              width={'50px'}
              height={'50px'}
            />
          </>
        ) : menudata.locationMenu == 'mission' ? (
          <>
            Select Color:
            <PrimarySettings
              value={setOtherColor}
              mycolor={
                state &&
                state.hasOwnProperty('locations') &&
                state.locations.length > 0 &&
                state.locations[menudata.locationIndex].hasOwnProperty(
                  'individual_page',
                ) &&
                state.locations[menudata.locationIndex].location_settings.about.color
              }
              width={'50px'}
              height={'50px'}
            />
          </>
        ) : menudata.locationMenu == 'reviews' ? (
          <>
            Select Color:
            <PrimarySettings
              value={setOtherColor}
              mycolor={
                state &&
                state.hasOwnProperty('locations') &&
                state.locations.length > 0 &&
                state.locations[menudata.locationIndex].hasOwnProperty(
                  'individual_page',
                ) &&
                state.locations[menudata.locationIndex].location_settings.reviews
                  .color
              }
              width={'50px'}
              height={'50px'}
            />
          </>
        ) : null}
      </Modal>
    </div>
  )
}

export default LocationMenu
