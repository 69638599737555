import React, { useEffect, useState, useContext } from 'react'
import LocTabs from '../previewtab/locationtabs'
import Context from '../../store/context'
import { AiOutlineRight } from 'react-icons/ai'
import { ServiceList } from 'gz-components'
import 'gz-components/dist/index.css'
import Slider from 'react-slick'
const LocationService = props => {
  const { state, actions } = useContext(Context)

  const [locHomeState, setlocHomeState] = useState({})
  const [ServiceData, setServiceData] = useState([])
  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const settingsForService = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  useEffect(() => {
    if (
      state &&
      ServiceData &&
      state.hasOwnProperty('locations') &&
      state.locations.length > 0 &&
      state.locations[props.data.locationIndex].hasOwnProperty('individual_page') &&
      state.locations[props.data.locationIndex].individual_page.hasOwnProperty(
        'services',
      ) &&
      state.locations[props.data.locationIndex].individual_page.services
    ) {
      setServiceData([
        ...state.locations[props.data.locationIndex].individual_page.services,
      ])
      console.log(
        state.locations[props.data.locationIndex].individual_page.services,
        'servces',
      )
    }
  }, [])
  return (
    <>
      <div>
        <Slider {...settings}>
          <div className="homescreen loc_home_top">
            <div className="hs_phone hs_phone_outer">
              <div className="frame-notch"> </div>
              <div className="loc_home_main">
                <div className="loc_ser_main">
                  {/* {ServiceData && <ServiceList services={ServiceData} />} */}
                  <Slider {...settingsForService}>
                    {ServiceData &&
                      ServiceData.map((node, i) => {
                        console.log(node.name)
                        if (
                          node.name === 'CustomService' ||
                          node.name === 'Premium'
                        ) {
                        } else
                          return (
                            <div className="loc_ser_container" key={i}>
                              <div className="loc_ser_heading">
                                <p>{node.name}</p>
                              </div>
                              <div className="loc_ser_services">
                                {node.services &&
                                  node.services.map((eachSer, j) => {
                                    return (
                                      <div key={j}>
                                        <p>{eachSer.name}</p>
                                        <p style={{ whiteSpace: 'nowrap' }}>
                                          $ {eachSer.price / 100}
                                        </p>
                                      </div>
                                    )
                                  })}
                              </div>
                            </div>
                          )
                      })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </>
  )
}

export default LocationService
