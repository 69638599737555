import React, { useState, useEffect, useContext, useCallback } from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import Context from '../../../store/context'
import _ from 'lodash'
const PrimarySettings = props => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState({
    // hex: '#000000',

    hex: props.mycolor,
  })
  const { state, actions } = useContext(Context)
  // const [palette, setPalette] = useState([])
  const [preset, setpreset] = useState([])

  const styles = reactCSS({
    default: {
      color: {
        width: props.width,
        height: props.height,
        borderRadius: '2px',

        background: color.hex,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  })

  useEffect(() => {
    setColor({ hex: props.mycolor })
    let others = []
    let temp = state
    temp.color.map((node, i) => {
      others.push(node.hex)
    })
    others = _.uniq(others)
    setpreset(others)
  }, [state, props])

  useEffect(() => {}, [preset])

  return (
    <>
      <div className="settings_primary_colorpicker">
        <div>
          <div
            style={styles.swatch}
            onClick={() => setDisplayColorPicker(!displayColorPicker)}
          >
            <div style={styles.color} />
          </div>
          {displayColorPicker ? (
            <div style={styles.popover}>
              <div
                style={styles.cover}
                onClick={() => setDisplayColorPicker(false)}
              />
              <SketchPicker
                color={color}
                presetColors={preset}
                onChange={colors => {
                  setColor({ hex: colors.hex })
                  props.value(colors)
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default PrimarySettings
