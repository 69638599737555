import React from 'react'
import { render } from 'react-dom'
import { initialiseBridge } from './lib/bridge'
import App from './App'
import './index.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const renderRoot = document.createElement('div')

render(<App />, renderRoot)

initialiseBridge()
