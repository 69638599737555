import React from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'

import { AdminContex } from './utils/contex'
import * as Library from './utils/localStorage'
import Container from './components/container'
const Routes = () => {
  const baseName = '/mblapp'
  // console.log(isAdmin,"isadmin",)
  const businessData = Library.getBusiness()
  console.log(businessData)
  return (
    <AdminContex.Provider value={businessData}>
      <Router basename={baseName}>
        <Switch>
          <Route path="/">
            <Container />
          </Route>
        </Switch>
      </Router>
    </AdminContex.Provider>
  )
}

export default Routes
