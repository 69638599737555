import React, { useEffect, useState, useContext } from 'react'
import LocTabs from '../previewtab/locationtabs'
import Context from '../../store/context'
import { AiOutlineRight } from 'react-icons/ai'
import Slider from 'react-slick'

const LocationHour = props => {
  const { state, actions } = useContext(Context)
  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const [locHomeState, setlocHomeState] = useState({})

  return (
    <>
      <div>
        <Slider {...settings}>
          <div className="homescreen">
            <div className="hs_phone hs_phone_outer">
              <div className="frame-notch"> </div>
              {/* <div className="loc_home_main"> */}
              <div
                className="loc_hour_main"
                style={{
                  backgroundColor:
                    state &&
                    state.hasOwnProperty('locations') &&
                    state.locations.length > 0 &&
                    state.locations[props.data.locationIndex].hasOwnProperty(
                      'individual_page',
                    ) &&
                    state.locations[props.data.locationIndex].location_settings.hours
                      .color,
                }}
              >
                {state &&
                  state.hasOwnProperty('locations') &&
                  state.locations.length > 0 &&
                  state.locations[props.data.locationIndex].hasOwnProperty(
                    'individual_page',
                  ) &&
                  state.locations[
                    props.data.locationIndex
                  ].individual_page.hours.map((node, i) => {
                    return (
                      <div className="loc_hour_container" key={i}>
                        <h2 className="align-self-center">{node.day}</h2>
                        <p className="align-self-center">{`${node.open}-${node.close}`}</p>
                      </div>
                    )
                  })}
              </div>
            </div>
            {/* </div> */}
          </div>
        </Slider>
      </div>
    </>
  )
}

export default LocationHour
