import {
  callPostApi,
  callGetApi,
  callTagApi,
  callGetAllApi,
  callUpdateApi,
  callDeleteApi,
  callNameApi,
  callUpdateTagApi,
  callDeleteTagApi,
  callCappUnpub,
  callAllCapp,
  callupdateCapp,
} from '../utils/apiCaller'
export const uploaderImage = imageData => {
  return callPostApi('/uploader', imageData)
}

export const updateName = imageData => {
  return callNameApi('/updatename', imageData)
}

export const updateTag = imageData => {
  return callUpdateTagApi('/updatetag', imageData)
}

export const deleteTag = imageData => {
  return callDeleteTagApi('/deletetag', imageData)
}

export const updaterImage = imageData => {
  console.log(imageData, 'imagedata')
  return callUpdateApi('/uploaderupdate', imageData)
}

export const getImage = data => {
  //heyo whats up
  console.log(data, 'data in getImage')
  return callGetApi(`/getimage`, data)
}

export const getAllImages = data => {
  return callGetAllApi('/getallimages', data)
}

export const getTags = data => {
  return callTagApi(
    '/growthzilla/remote/public/protected/business/3/tagcloud/get',
    data,
  )
}

export const getdeleteImage = data => {
  return callDeleteApi('/deleteimage', data)
}

///////////////capp starts
export const cappUnpublished = data => {
  console.log(data, 'data')
  return callCappUnpub('/cappunpublished', data)
}

export const getAllCapp = data => {
  return callAllCapp('/callallcapp', data)
}

export const PublishCapp = data => {
  return callupdateCapp('/publishcapp', data)
}

export const updateCapp = data => {
  return callupdateCapp('/updatecapp', data)
}
