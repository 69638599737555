import React from 'react'
import AppPortal from './app-portal'
import Routes from '../Routes'

let instance = undefined

const registerInstance = _instance => {
  instance = _instance
}

const unregisterInstance = _instance => {
  if (instance === _instance) {
    instance = undefined
  }
}

export const getInstance = () => instance

const randomId = () => Math.random().toString(36)

class ExtPortalManager extends React.Component {
  state = {
    portals: [],
    data: {},
  }

  componentDidMount() {
    registerInstance(this)
  }

  componentWillUnmount() {
    unregisterInstance(this)
  }

  setData(key, data) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [key]: data,
      },
    })
  }

  getData(key) {
    return this.state.data[key] || null
  }

  addAppPortal(el, props) {
    const id = randomId()

    this.setState({
      portals: [
        ...this.state.portals,
        {
          id,
          domEl: el,
          props,
        },
      ],
    })

    return () => {
      this.setState({
        portals: this.state.portals.filter(p => p.id !== id),
      })
    }
  }

  clearAllPortals() {
    console.log('clearing all portals')
    this.setState({
      portals: [],
    })
  }

  render() {
    const { portals } = this.state
    console.log(portals, 'potals')
    return (
      <>
        {portals.map(portal => (
          <AppPortal key={portal.id} portalRootEl={portal.domEl}>
            <Routes />
          </AppPortal>
        ))}
      </>
    )
  }
}

export default ExtPortalManager
