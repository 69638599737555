import React, { useEffect, useState, useContext } from 'react'
import LocTabs from '../previewtab/locationtabs'

import { AiOutlineRight } from 'react-icons/ai'
import Cropper from '../logo/cropper'
import MarkDown from 'markdown-to-jsx'
import ImageGallery from '../imagegallery'
import MarkDownComponent from '../markdown/markdowneditor'
import { Modal, Button } from 'antd'
import Slider from 'react-slick'
import Context from '../../store/context'
const LocationHome = props => {
  const { state, actions } = useContext(Context)
  const [contextState, setcontextState] = useState(null)
  const [locHomeState, setlocHomeState] = useState(null)
  const [thisState, setthisState] = useState({
    modalVisible: false,
    modalName: 'Image Gallery',
  })
  const [homeState, setHomeState] = useState({
    modalvisible: false,
    Modalname: '',
    SelectedImage: '',
    width: 0,
    height: 0,
    slidenumber: 0,
    ImageFor: '',
    shouldDelete: false,
  })
  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 0,
  }

  const imageClicked = (node, uid, display_name) => {
    // console.log(state, 'after temp')
    setHomeState({
      ...homeState,
      SelectedImage: node,
      Modalname: 'Cropper',
      selectedImgId: uid,
      selectedName: display_name,
    })
  }

  const editImage = node => {
    let h, w, x, y, id, display_name
    let dim = node.getCroppingRect()
    h = dim.height.toString() //.replace('.', '-')
    w = dim.width.toString() //.replace('.', '-')
    x = dim.x.toString() //.replace('.', '-')
    y = dim.y.toString() //.replace('.', '-')
    id = homeState.selectedImgId.split('.')
    display_name = homeState.selectedName
    if (homeState.ImageFor === 'Background') {
      let temp = state

      temp.locations[
        props.data.locationIndex
      ].individual_page.homeimage.src = `${process.env.MOBILE_APP_BACKEND_URL}/getcroppedimage/${h}/${w}/${x}/${y}/env/${id[0]}/${display_name}.${id[1]}` //node.getImageScaledToCanvas().toDataURL()
      actions({ type: 'setState', payload: { ...temp } })
    } else if (homeState.ImageFor === 'Logo') {
      let temp = contextState
      temp.logo.src = `${process.env.MOBILE_APP_BACKEND_URL}/getcroppedimage/${h}/${w}/${x}/${y}/env/${id[0]}/${display_name}.${id[1]}`

      actions({ type: 'setState', payload: { ...temp } })
    }

    setHomeState({ ...homeState, Modalname: '', modalvisible: false })
  }

  useEffect(() => {
    console.log('on home')
    setcontextState({ ...state })
  }, [state, props])

  return (
    <>
      <div>
        <Slider {...settings}>
          <div className="homescreen">
            <div className="hs_phone hs_phone_outer">
              <div className="frame-notch"> </div>
              <div className="loc_home_main">
                <div
                  className="loc_home_upload"
                  onClick={() => {
                    setHomeState({
                      ...homeState,
                      Modalname: 'Image Gallery',
                      width: 300,
                      height: 400,
                      ImageFor: 'Background',
                      modalvisible: true,
                    })
                  }}
                >
                  {contextState &&
                  contextState.hasOwnProperty('locations') &&
                  contextState.locations.length > 0 &&
                  contextState.locations[props.data.locationIndex].hasOwnProperty(
                    'individual_page',
                  ) &&
                  contextState.locations[props.data.locationIndex].individual_page
                    .homeimage.src ? (
                    <img
                      width="256px"
                      src={
                        contextState.locations[props.data.locationIndex]
                          .individual_page.homeimage.src
                      }
                    />
                  ) : (
                    <div className="upload_img">
                      <h4>+ Upload image</h4>
                    </div>
                  )}
                  <div
                    className="loc_home_logo"
                    style={
                      state &&
                      state.hasOwnProperty('locations') &&
                      state.locations.length > 0 &&
                      state.locations[props.data.locationIndex].hasOwnProperty(
                        'individual_page',
                      ) &&
                      state.locations[props.data.locationIndex].individual_page
                        .homeimage.src
                        ? { top: '-310px' }
                        : { top: '0' }
                    }
                  >
                    {state.logo.src ? (
                      <img src={state.logo.src} />
                    ) : (
                      <div>
                        <h4>+ Upload logo</h4>
                      </div>
                    )}
                    <h3>
                      {state &&
                        state.hasOwnProperty('locations') &&
                        state.locations.length > 0 &&
                        state.locations[props.data.locationIndex].city}
                    </h3>
                    <p>
                      {state &&
                        state.hasOwnProperty('locations') &&
                        state.locations.length > 0 &&
                        state.locations[props.data.locationIndex].address}
                    </p>
                    <div
                      className="loc_home_phone"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: state.logo.src ? '120px' : '200px',
                      }}
                    >
                      <p>
                        {state &&
                          state.hasOwnProperty('locations') &&
                          state.locations.length > 0 &&
                          state.locations[props.data.locationIndex].phone}
                      </p>
                      <button className="loc_home_book">Book Now</button>
                    </div>
                  </div>
                </div>

                <div className="loc_home_menu">
                  {contextState &&
                    contextState.hasOwnProperty('locations') &&
                    contextState.locations.length > 0 &&
                    contextState.locations[props.data.locationIndex].hasOwnProperty(
                      'individual_page',
                    ) &&
                    contextState.locations[props.data.locationIndex]
                      .location_settings.service.hidden && (
                      <div className="loc_home_each">
                        <p>
                          {
                            contextState.locations[props.data.locationIndex]
                              .location_settings.service.service_label
                          }
                        </p>
                        <AiOutlineRight />
                      </div>
                    )}

                  {state &&
                    state.hasOwnProperty('locations') &&
                    state.locations.length > 0 &&
                    state.locations[props.data.locationIndex].hasOwnProperty(
                      'individual_page',
                    ) &&
                    state.locations[props.data.locationIndex].location_settings
                      .gallery.hidden && (
                      <div className="loc_home_each">
                        {console.log(
                          state.locations[props.data.locationIndex].location_settings
                            .gallery.hidden,
                        )}
                        <p>
                          {
                            state.locations[props.data.locationIndex]
                              .location_settings.gallery.gallery_label
                          }
                        </p>
                        <AiOutlineRight />
                      </div>
                    )}
                  {state &&
                    state.hasOwnProperty('locations') &&
                    state.locations.length > 0 &&
                    state.locations[props.data.locationIndex].hasOwnProperty(
                      'individual_page',
                    ) &&
                    state.locations[props.data.locationIndex].location_settings.staff
                      .hidden && (
                      <div className="loc_home_each">
                        <p>
                          {
                            state.locations[props.data.locationIndex]
                              .location_settings.staff.staff_label
                          }
                        </p>
                        <AiOutlineRight />
                      </div>
                    )}
                  {state &&
                    state.hasOwnProperty('locations') &&
                    state.locations.length > 0 &&
                    state.locations[props.data.locationIndex].hasOwnProperty(
                      'individual_page',
                    ) &&
                    state.locations[props.data.locationIndex].location_settings.hours
                      .hidden && (
                      <div className="loc_home_each">
                        <p>
                          {
                            state.locations[props.data.locationIndex]
                              .location_settings.hours.hours_label
                          }
                        </p>
                        <AiOutlineRight />
                      </div>
                    )}

                  {state &&
                    state.hasOwnProperty('locations') &&
                    state.locations.length > 0 &&
                    state.locations[props.data.locationIndex].hasOwnProperty(
                      'individual_page',
                    ) &&
                    state.locations[props.data.locationIndex].location_settings.about
                      .hidden && (
                      <div className="loc_home_each">
                        <p>
                          {
                            state.locations[props.data.locationIndex]
                              .location_settings.about.aboutus_label
                          }
                        </p>
                        <AiOutlineRight />
                      </div>
                    )}

                  {state &&
                    state.hasOwnProperty('locations') &&
                    state.locations.length > 0 &&
                    state.locations[props.data.locationIndex].hasOwnProperty(
                      'individual_page',
                    ) &&
                    state.locations[props.data.locationIndex].location_settings
                      .reviews.hidden && (
                      <div className="loc_home_each">
                        <p>
                          {
                            state.locations[props.data.locationIndex]
                              .location_settings.reviews.review_label
                          }
                        </p>
                        <AiOutlineRight />
                      </div>
                    )}
                </div>
              </div>
              {/* <LocTabs /> */}
            </div>
          </div>
        </Slider>
      </div>
      <Modal
        title={homeState.Modalname}
        visible={homeState.modalvisible}
        onOk={() => {
          {
            /* console.log('OK') */
          }
          setHomeState({ ...homeState, modalvisible: false, Modalname: '' })
        }}
        onCancel={() => {
          {
            /* console.log('Cancel') */
          }
          setHomeState({ ...homeState, modalvisible: false, Modalname: '' })
        }}
        destroyOnClose={true}
        footer={
          homeState.Modalname === 'Cropper'
            ? null
            : [
                <Button
                  onClick={() =>
                    setHomeState({
                      ...homeState,
                      modalvisible: false,
                      Modalname: '',
                    })
                  }
                >
                  Cancel
                </Button>,
                <Button
                  type="primary"
                  onClick={() =>
                    setHomeState({
                      ...homeState,
                      modalvisible: false,
                      Modalname: '',
                    })
                  }
                >
                  Ok
                </Button>,
              ]
        }
      >
        {homeState.Modalname === 'Image Gallery' ||
        homeState.Modalname === 'Image Gallery for logo' ? (
          <ImageGallery imageClicked={imageClicked} />
        ) : homeState.Modalname === 'Cropper' ? (
          <Cropper
            image={homeState.SelectedImage}
            width={homeState.width}
            height={homeState.height}
            edited={editImage}
          />
        ) : null}
      </Modal>
    </>
  )
}

export default LocationHome
