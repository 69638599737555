import React, { useState, useContext, useEffect } from 'react'

import AllHomeComponent from './allhomescreen'
import { Menu, Dropdown, Button, Tooltip, Modal } from 'antd'
import {
  cappUnpublished,
  getAllCapp,
  updateCapp,
  PublishCapp,
} from '../../api/index'
import Context from '../../store/context'
import moment from 'moment'
import Locationcontainer from '../location/locationcontainer'
import { BsPhone, BsClockHistory } from 'react-icons/bs'
const HomeScreen = () => {
  const { state, actions } = useContext(Context)
  const [allCapp, setallCapp] = useState(null)
  const [currentVersionId, setcurrentVersionId] = useState(null)
  const [effect, seteffect] = useState(false)
  const [homeState, sethomeState] = useState({
    modalVisible: false,
    modalName: '',
  })
  let allData = {
    name: 'Uzeli1',
    user_id: '123',
    business_id: '12',
    is_published: 'draft',
    capp_design_json: '',
    version_name: 'Version name',
    description: 'All the desc',
    current_draft: 'id-123',
  }

  const saveDesign = () => {
    allData.capp_design_json = JSON.stringify(state)

    let myfile = new FormData()
    for (var key in allData) {
      myfile.append(key, allData[key])
    }

    cappUnpublished(myfile).then(data => {
      // console.log(data, 'after saving state')
      seteffect(!effect)
    })
  }
  const selectVersion = node => {
    // console.log(state, node)
    setcurrentVersionId(node.id)
    actions({ type: 'setState', payload: { ...JSON.parse(node.capp_design_json) } })
  }

  const pubDesign = () => {
    allData.id = currentVersionId

    allData.capp_design_json = JSON.stringify(state)
    allData.is_published = 'published'

    let myfile = new FormData()
    for (var key in allData) {
      myfile.append(key, allData[key])
    }
    cappUnpublished(myfile).then(data => {
      // console.log(data, 'after saving state')
      seteffect(!effect)
    })
  }

  useEffect(() => {
    // console.log('get all caps the after IO')
    getAllCapp('12') //use first business id ->
      .then(data => {
        console.log(data, 'data after getting all versions')

        data.data.reduce((a, b) => {
          return new Date(a.MeasureDate) > new Date(b.MeasureDate) ? a : b
        })

        console.log(JSON.parse(localStorage.getItem('business')), 'businesses')

        if (data.data) {
          setallCapp(data.data)
          selectVersion(data.data[data.data.length - 1])
        } else {
          let newState = state
          let business = JSON.parse(localStorage.getItem('business'))
          let _temp = []

          business.map((node, i) => {
            let singleLocation = {}
            // console.log(node, node.employees, i, node.info.servicecategories, 'node')
            singleLocation.location_settings = {
              // employee_mode: 'lite',
              default: false,
              home: { home_label: 'Home' },
              staff: {
                staff_label: 'Our Barbers',
                staff_mode: 'lite',
                color: '#c11d2e',
                hidden: true,
              },
              about: {
                aboutus_label: 'Our Mission',
                color: '#c11d2e',
                hidden: true,
              },
              service: { service_label: 'Services', color: '#c11d2e', hidden: true },
              gallery: {
                gallery_label: 'Gallery',
                logo: '',
                color: '#c11d2e',
                hidden: true,
              },
              hours: { hours_label: 'Hours', color: '#c11d2e', hidden: true },
              reviews: { review_label: 'Reviews', color: '#c11d2e', hidden: true },
            }
            singleLocation.region = node.info.state
            singleLocation.locationName = node.info.name
            singleLocation.address = node.info.address
            singleLocation.city = node.info.city
            singleLocation.state = node.info.state
            singleLocation.zip = node.info.zipcode
            singleLocation.phone = node.info.phone
            singleLocation.business_id = node.business.business_code
            singleLocation.app_id = node.info.appointmentBusinessId
            singleLocation.external_url = ''
            singleLocation.hero_image = ''
            singleLocation.individual_page = {
              homeimage: {
                src: '',
              },
              services: node.info.servicecategories,
              employee: [],
              gallery: [
                {
                  image: {
                    src: '',
                  },
                  caption: '',
                },
              ],
              socialmedia: [],
              about_us: {
                image: {
                  src: '',
                },
                paragraph: '',
              },
              hours: [],
              reviews: [
                {
                  customer_id: 123,
                  customer_name: '',
                  review: '',
                  rating: '0',
                  customer_pic: {
                    src: '',
                  },
                },
              ],
            }
            if (node.employees && node.employees.length > 0)
              node.employees.map((eachEmp, j) => {
                let emp = {
                  employee_id: eachEmp.id,
                  name: eachEmp.full_name,
                  title: eachEmp.role,
                  bio: '',
                  phone: '',
                  email: '',
                  profile_image: {
                    src: '',
                  },
                  social_media: [
                    {
                      type: 'facebook',
                      url: 'www.facebook.com',
                      icon_url: 'url',
                    },
                    {
                      type: 'instagram',
                      url: 'www.facebook.com',
                      icon_url: 'url',
                      handle: '@uzeli',
                    },
                  ],
                }

                singleLocation.individual_page.employee.push(emp)
              })
            if (node.business.offce_hours) {
              let hours = JSON.parse(node.business.offce_hours)
              let _hours = Object.keys(hours)
              const pushHour = key => {
                let _tempObj = {
                  day: '',
                  closed: false,
                  open: '',
                  close: '',
                }

                _tempObj.day = key
                _tempObj.closed = false
                _tempObj.open = `${moment(hours[key].open, 'HH').format('hh:mm a')}`
                _tempObj.close = `${moment(hours[key].close, 'HH').format(
                  'hh:mm a',
                )}`
                singleLocation.individual_page.hours.push(_tempObj)
              }

              _hours.map(key => {
                if (
                  key == 'sunday' ||
                  key == 'monday' ||
                  key == 'tuesday' ||
                  key == 'wednesday' ||
                  key == 'thursday' ||
                  key == 'friday' ||
                  key == 'saturday'
                )
                  pushHour(key)
                else {
                  let _h = [
                    {
                      day: 'sunday',
                      closed: false,
                      open: `${moment(hours.hours[0].start, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                      close: `${moment(hours.hours[0].end, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                    },
                    {
                      day: 'monday',
                      closed: false,
                      open: `${moment(hours.hours[0].start, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                      close: `${moment(hours.hours[0].end, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                    },
                    {
                      day: 'tuesday',
                      closed: false,
                      open: `${moment(hours.hours[0].start, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                      close: `${moment(hours.hours[0].end, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                    },
                    {
                      day: 'wednesday',
                      closed: false,
                      open: `${moment(hours.hours[0].start, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                      close: `${moment(hours.hours[0].end, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                    },
                    {
                      day: 'thursday',
                      closed: false,
                      open: `${moment(hours.hours[0].start, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                      close: `${moment(hours.hours[0].end, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                    },
                    {
                      day: 'friday',
                      closed: false,
                      open: `${moment(hours.hours[0].start, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                      close: `${moment(hours.hours[0].end, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                    },
                    {
                      day: 'saturday',
                      closed: false,
                      open: `${moment(hours.hours[0].start, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                      close: `${moment(hours.hours[0].end, 'HH:mm').format(
                        'hh:mm a',
                      )}`,
                    },
                  ]
                  singleLocation.individual_page.hours = _h
                }
              })
            }
            _temp.push(singleLocation)
          })

          newState.locations = _temp
          actions({ type: 'setState', payload: { ...newState } })
        }
      })
      .catch(err => console.log(err, 'error in fetching versions'))
  }, [effect])

  const menu = () => {
    return (
      <Menu>
        {allCapp &&
          allCapp.map((node, i) => {
            let time = moment(node.updated_at).format('Do MMM YY , hh:mm a')
            return (
              <Menu.Item key={i} onClick={() => selectVersion(node)}>
                <div className="dropdown_history">
                  {node.is_published == 'published' ? (
                    <Tooltip placement="left" title={time}>
                      <BsPhone />
                      Published
                    </Tooltip>
                  ) : node.is_published == 'prev_published' ? (
                    <Tooltip placement="left" title={time}>
                      <BsPhone />
                      Last Publish
                    </Tooltip>
                  ) : (
                    <Tooltip placement="left" title={time}>
                      <BsClockHistory />
                      Draft
                    </Tooltip>
                  )}
                </div>
              </Menu.Item>
            )
          })}
      </Menu>
    )
  }

  const onOk = () => {
    if (homeState.modalName === 'publish?') {
      pubDesign()
      sethomeState({ ...homeState, modalVisible: false, modalName: '' })
    } else {
      saveDesign()
      sethomeState({ ...homeState, modalVisible: false, modalName: '' })
    }
  }

  // console.log('home Screen')
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="each_heading">
          <h3>Home Slides</h3>
        </div>
        <div className="">
          <Button
            type="primary"
            onClick={() =>
              sethomeState({
                ...homeState,
                modalName: 'publish?',
                modalVisible: true,
              })
            }
          >
            Publish
          </Button>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>History</Button>
          </Dropdown>
          <Button
            onClick={() =>
              sethomeState({ ...homeState, modalName: 'save?', modalVisible: true })
            }
          >
            Save
          </Button>
        </div>
      </div>
      <br />
      <div className="actual_component">
        <AllHomeComponent />
      </div>
      <br />
      <br />
      <div className="each_heading">
        <h3>Locations</h3>
      </div>
      <br />
      <div className="actual_location">
        <Locationcontainer />
      </div>
      <Modal
        title={`Do you want to ${homeState.modalName}`}
        visible={homeState.modalVisible}
        onOk={() => {
          onOk()
        }}
        onCancel={() => {
          sethomeState({ ...homeState, modalVisible: false, modalName: '' })
        }}
        okText="Yes"
        cancelText="No"
      ></Modal>
    </>
  )
}

export default HomeScreen
