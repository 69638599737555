import React, { useState, useContext, useEffect } from 'react'

import { FilesContext } from './context/filescontext'
const NewHome = props => {
  // console.log(props, 'props')
  const filesContext = useContext(FilesContext)
  const [imageUrl, setImageUrl] = useState([])
  let myimages = []

  useEffect(() => {
    filesContext.forEach(async node => {
      // console.log(node, 'node of each image')
      let uid = node.id.split('.')
      // console.log(
      //   `${process.env.MOBILE_APP_BACKEND_URL}/getimage/small/${uid[0]}/${node.path}/${node.display_name}`,
      // )

      // let src = await new Promise(resolve => {
      //   const reader = new FileReader()
      //   // reader.readAsDataURL(node.originFileObj)
      //   l
      //   console.log(
      //     `http://nodemodule.com:8080/getimage/small/${uid[0]}/${node.path}/${node.display_name}`,
      //     'image',
      //   )
      //   reader.readAsDataURL(
      //     `http://nodemodule.com:8080/getimage/small/${uid[0]}/${node.path}/${node.display_name}`,
      //   )
      //   reader.onload = () => resolve(reader.result)
      // })
      // let elem = document.createElement('img')

      myimages.push(node)
      setImageUrl([...myimages])
    })
    return () => {}
  }, [filesContext])

  return (
    <>
      <div className="img_gallery">
        {imageUrl.map((node, i) => {
          {
            // console.log(node, 'node')
          }
          let uid = node.id.split('.')
          return (
            <img
              key={uid[0]}
              src={`${process.env.MOBILE_APP_BACKEND_URL}/getimage/small/${uid[0]}/${node.path}/${node.display_name}.${uid[1]}`}
              style={{ width: '100px', height: '100px' }}
              onClick={() => {
                props.imageClicked(
                  `${process.env.MOBILE_APP_BACKEND_URL}/getimage/medium/${uid[0]}/${node.path}/${node.display_name}.${uid[1]}`,
                  node.id,
                  node.display_name,
                )
              }}
            />
          )
        })}
      </div>
    </>
  )
}

export default NewHome
